<template>
  <div class="login-page">
    <div class="login_view">
      <div class="login_title">登录</div>
      <Login @on-submit="handleSubmit">
        <Mobile name="username" />
        <Password name="password" style="margin-bottom: 30px;" />
        <Submit />
      </Login>
      <div class="logon_view" @click="toLogon">暂无账户，前往注册</div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      autoLogin: true
    }
  },
  created() {

  },
  methods: {
    handleSubmit(valid, { username, password }) {
      if (valid) {
        axios({
          method: "post",
          url: "https://metaverse.yccdl.cn/admin/sale/login",
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: localStorage.getItem("token"),
          },
          withCredentials: true,
          data: {
            sale_name: username,
            sale_pw: password
          },
        })
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              localStorage.setItem('token', res.data.data.token);
              localStorage.setItem('user', res.data.data.data.sale_nickname);
              localStorage.setItem('admin_type', res.data.data.data.admin_type);
              if (res.data.data.data.organization != null) {
                localStorage.setItem('user_cityId', res.data.data.data.organization.city_id);
              }
              console.log(res.data.data.data)
              // 
              this.$router.push({
                path: res.data.data.data.admin_type == 'sale' ? '/' : '/cityAdmin',
              });
            } else {
              this.$Modal.confirm({
                title: res.data.msg,
                content: '',
                onOk: () => {
                }
              })
            }

          })
          .catch((res) => {
            console.log(res);
          });
      } else (
        this.$Modal.info({
          title: '提示！',
          content: '请输入格式正确的邮箱或手机号'
        })
      )
    },
    // 跳转注册
    toLogon() {
      this.$router.push({
        path: "/logon",
      });
    }
  }
}
</script>
<style lang="less" scoped>
.login-page {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(150deg, #4a5cc4, #72bbff);
  overflow: hidden;

  .login_view {
    padding: 30px 60px 60px 60px;
    border-radius: 20px;
    background: #fff;
    width: 60vh;
    float: right;
    margin: 30vh;

    .login_title {
      font-size: 48px;
      font-weight: 600;
      text-align: left;
      margin-block: 30px;
      padding-bottom: 30px;
      // border-bottom: 1px solid rgba(74, 92, 196, 0.4);
    }

    .logon_view {
      text-align: center;
      padding-top: 30px;
      margin-top: 30px;
      border-top: 1px solid rgba(74, 92, 196, 0.4);
      color: #72bbff;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>