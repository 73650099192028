<template>
  <div class="role">
    <header-view></header-view>
    <div class="search_view" style="margin:40px 20px">
      <Row :gutter="16">
        <Col span="6" style="display:flex">
        <div style="min-width: 120px;">负责人电话：</div>
        <Input v-model="sale_phone" style="width: 440px" />
        </Col>
        <Col span="6" style="display:flex">
        <div style="min-width: 120px;">查询日期：</div>
        <DatePicker type="daterange" placement="bottom-end" placeholder="Select date" style="width: 200px"
          @on-change="changeData" />
        </Col>
      </Row>
      <Row :gutter="16" style="margin-top: 20px;">
        <Col span="15">
        </Col>
        <Col span="3">
        <Button type="primary" style="width: 80%;" @click="getAdd">添加</Button>
        </Col>
        <Col span="3">
        <Button style="width: 80%;" type="primary" @click="getsearch">搜索</Button>
        </Col>
        <Col span="3">
        <Button style="width: 80%;" @click="research">重置</Button>
        </Col>
      </Row>
    </div>
    <div class="table_view">
      <Table :columns="columns" border :data="data">
      </Table>
      <Page @on-change="changePage" style="margin-top: 20px" page-size="15" :total="last_page" v-model="page"
        show-total />
    </div>
    <!--  新增 编辑 -->
    <Modal title="添加成员" v-model="modaladd" class-name="vertical-center-modal">
      <Form ref="addform" :model="addform" :rules="ruleValidateAdd" :label-width="120">
        <FormItem label="业务员姓名" prop="sale_nickname">
          <Input v-model="addform.sale_nickname"></Input>
        </FormItem>
        <FormItem label="业务员电话" prop="sale_phone">
          <Input v-model="addform.sale_phone"></Input>
        </FormItem>
        <FormItem label="密码" prop="sale_pw">
          <Input type="password" v-model="addform.sale_pw" placeholder="Password"></Input>
        </FormItem>
        <FormItem label="确认密码" prop="sale_again_pw">
          <Input type="password" v-model="addform.sale_again_pw" placeholder="Password"></Input>
        </FormItem>
        <FormItem label="角色类型" prop="admin_type">
          <Select v-model="addform.admin_type" filterable clearable style="width: 77.5%;">
            <Option v-for="item in typeList" :value="item.type" :key="item.type">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="所在机构" prop="school_id" v-if="addform.admin_type == 'school'">
          <Select v-model="addform.school_id" filterable clearable style="width: 77.5%;">
            <Option v-for="item in schoolList" :value="item.id" :key="item.id">{{ item.schoolname }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('addform')">提交</Button>
          <Button @click="handleReset('addform')" style="margin-left: 8px">取消</Button>
        </FormItem>
      </Form>
      <template v-slot:footer>
        <div></div>
      </template>
    </Modal>
    <Modal title="成员编辑" v-model="modalChage" class-name="vertical-center-modal">
      <Form ref="changeForm" :model="changeForm" :rules="ruleValidateAdd" :label-width="120">
        <FormItem label="业务员姓名" prop="sale_nickname">
          <Input v-model="changeForm.sale_nickname"></Input>
        </FormItem>
        <FormItem label="业务员电话" prop="sale_phone">
          <Input v-model="changeForm.sale_phone"></Input>
        </FormItem>
        <FormItem label="密码" prop="sale_pw">
          <Input type="password" v-model="changeForm.sale_pw" placeholder="Password"></Input>
        </FormItem>
        <FormItem label="确认密码" prop="sale_again_pw">
          <Input type="password" v-model="changeForm.sale_again_pw" placeholder="Password"></Input>
        </FormItem>
        <FormItem label="角色类型" prop="admin_type">
          <Select v-model="changeForm.admin_type" filterable clearable style="width: 77.5%;">
            <Option v-for="item in typeList" :value="item.type" :key="item.type">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="所在机构" prop="school_id" v-if="changeForm.admin_type == 'school'">
          <Select v-model="changeForm.school_id" filterable clearable style="width: 77.5%;">
            <Option v-for="item in schoolList" :value="item.id" :key="item.id">{{ item.schoolname }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmitC('changeForm')">提交</Button>
          <Button @click="handleResetC('changeForm')" style="margin-left: 8px">取消</Button>
        </FormItem>
      </Form>
      <template v-slot:footer>
        <div></div>
      </template>
    </Modal>
    <!-- 业务码 显示 -->
    <Modal title="业务码" v-model="modelCode" class-name="vertical-center-modal">
      <img style="width: 100%;height: 100%;" :src="codeImg" alt="">
    </Modal>
  </div>
</template>

<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
export default {
  components: {
    'header-view': header
  },
  data() {
    return {
      columns: [{
        title: '序号',
        type: 'index',
        align: 'center',
        minWidth: 80,
      }, {
        title: '姓名',
        key: 'sale_nickname',
        align: 'center',
        minWidth: 100,
      }, {
        title: '电话号码',
        key: 'sale_phone',
        align: 'center',
        minWidth: 120,
      }, {
        title: '入职日期',
        key: 'add_date',
        align: 'center',
        minWidth: 160,
      }, {
        title: '用户身份',
        key: 'check_status',
        align: 'center',
        minWidth: 120,
        render: (h, params) => {
          if (params) {
            if (params.row.admin_type == 'organization') {
              return h('span', '频道负责人')
            } else {
              return h('span', '机构负责人')
            }
          }
        }
      }, {
        title: '销售额',
        key: 'pay_total',
        align: 'center',
        minWidth: 100,
        render: (h, params) => {
          return h('span', params.row.share.pay_total)
        }
      }, {
        title: '分润金额',
        key: 'share_total',
        align: 'center',
        minWidth: 100,
        render: (h, params) => {
          return h('span', params.row.share.share_total)
        }
      }, {
        title: '成单数量',
        key: 'pay_count',
        align: 'center',
        minWidth: 100,
        render: (h, params) => {
          return h('span', params.row.share.pay_count)
        }
      },
      {
        title: '分润占比',
        key: 'pay_count',
        align: 'center',
        minWidth: 100,
        render: (h, params) => {
          return h('span', params.row.share.rate + '%')
        }
      }, {
        title: '审核状态',
        key: 'check_status',
        align: 'center',
        minWidth: 120,
        render: (h, params) => {
          if (params) {
            if (params.row.check_status == '0') {
              return h('span', '待审核')
            } else if (params.row.check_status == '1') {
              return h('span', '已审核')
            } else {
              return h('span', '审核失败')
            }
          }
        }
      }, {
        title: '操作',
        key: 'id',
        minWidth: 250,
        render: (h, params) => {
          return h('div', [
            h('span', {
              class: 'checkBtn',
              onClick: () => {
                this.getCode(params.row)
              }
            }, '获取业务码'),
            h('span', {
              class: 'checkBtn',
              onClick: () => {
                this.changethis(params.row)
              }
            }, '编辑'),
            h('span', {
              class: 'chceBtn_g',
              onClick: () => {
                this.deleteThis(params.row)
              }
            }, '删除')
          ])
        }
      }],
      data: [],
      last_page: 0,
      page: 1,
      sale_phone: '',
      // 列表操作用数据：
      modaladd: false,
      addform: {
        sale_pw: '',
        sale_again_pw: '',
        sale_phone: '',
        sale_nickname: '',
        admin_type: '',
        school_id: ''
      },
      ruleValidateAdd: {
        sale_nickname: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        sale_phone: [
          { required: true, message: '电话号码不能为空', trigger: 'blur' }
        ],
        sale_pw: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        sale_again_pw: [
          { required: true, message: '请填写确认密码', trigger: 'blur' }
        ],
        admin_type: [
          { required: true, message: '请选择所在组织类型', trigger: 'change' }
        ],
      },
      passwordLen: 0,
      schoolList: [],
      typeList: [
        {
          type: 'school',
          name: '机构负责人'
        }, {
          type: 'organization_sale',
          name: '业务员'
        }
      ],
      changeForm: {
        sale_pw: '',
        sale_again_pw: '',
        sale_phone: '',
        sale_nickname: '',
        admin_type: '',
        school_id: ''
      },
      modalChage: false,
      userId: '',
      modelCode: false,
      codeImg: '',
      start_date: '',
      end_date: '',
    }
  },
  created() {
    if (localStorage.getItem("token")) {
      this.getAdminUserList();
      this.getSchool()
    } else {
      this.$router.push({
        path: "/login",
      });
    }
  },
  computed: {
    // 密码强度提示文案等
    passwordTip() {
      let strong = '强';
      let className = 'strong';
      let percent = this.passwordLen > 10 ? 10 : this.passwordLen;
      let color = '#19be6b';

      if (this.passwordLen < 6) {
        strong = '太短';
        className = 'low';
        color = '#ed4014';
      } else if (this.passwordLen < 10) {
        strong = '中';
        className = 'medium';
        color = '#ff9900';
      }

      return {
        strong,
        class: 'demo-register-tip-' + this.passwordLen < 6 ? 'low' : (this.passwordLen < 10 ? 'medium' : 'strong'),
        percent: percent * 10,
        color
      }
    }
  },
  methods: {
    // 获取业务码
    getCode(row) {
      axios({
        url: "https://metaverse.yccdl.cn/admin/sale/getUserCodeBySaleId",
        params: {
          sale_id: row.id
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.modelCode = true;
            this.codeImg = res.data.data.base64_img;
          }
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
    changeData(e) {
      console.log(e)
      this.start_date = e[0];
      this.end_date = e[1];
      this.getAdminUserList();
    },
    getSchool() {
      axios({
        url: "https://metaverse.yccdl.cn/admin/organization/getSchool",
        params: {
          city_id: localStorage.getItem('user_cityId')
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          this.schoolList = res.data.data;
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
    getAdd() {
      this.modaladd = true;
    },
    handleSubmit(name) {
      console.log(this.addform)
      let form = this.addform;
      form.check_status = 0
      this.$refs[name].validate((valid) => {
        if (valid) {
          console.log(this.addform)
          axios({
            url: "https://metaverse.yccdl.cn/admin/organization/insertAdminUser",
            method: 'post',
            data: form,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token"),
            },
            withCredentials: false,
          })
            .then((res) => {
              console.log(res);

              this.$Modal.confirm({
                title: res.data.msg,
                content: '',
                onOk: () => {
                  if (res.data.code == 200) {
                    this.$refs['addform'].resetFields();
                    this.modaladd = false;
                    this.data = [];
                    this.page = 1;
                    this.research();
                  }
                }
              })
            })
            .catch((res) => {
              // console.log(res.data.data);
            });
          // this.$Message.success('Success!');
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.modaladd = false;
    },
    changethis(row) {
      console.log(row)
      this.userId = row.id;
      axios({
        url: "https://metaverse.yccdl.cn/admin/organization/getAdminUserDetail",
        params: {
          id: row.id
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          let data = res.data.data;
          this.changeForm = {
            sale_pw: data.sale_pw,
            sale_again_pw: data.sale_pw,
            sale_phone: data.sale_phone,
            sale_nickname: data.sale_nickname,
            admin_type: data.admin_type,
            school_id: data.school_id
          }
          this.modalChage = true;
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
    handleResetC(name) {
      this.$refs[name].resetFields();
      this.modalChage = false;
    },
    handleSubmitC(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = this.changeForm;
          form.id = this.userId;
          form.check_status = 0;
          axios({
            url: "https://metaverse.yccdl.cn/admin/organization/updateAdminUser",
            method: 'post',
            data: form,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token"),
            },
            withCredentials: false,
          })
            .then((res) => {
              console.log(res);
              this.$Modal.confirm({
                title: res.data.msg,
                content: '',
                onOk: () => {
                  if (res.data.code == 200) {
                    this.$refs['changeForm'].resetFields();
                    this.id = '';
                    this.modalChage = false;
                    this.data = [];
                    this.page = 1;
                    this.research();
                  }
                }
              })
            })
            .catch((res) => {
              // console.log(res.data.data);
            });
          // this.$Message.success('Success!');
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    deleteThis(row) {
      this.$Modal.confirm({
        title: '是否删除该成员信息',
        content: '',
        onOk: () => {
          axios({
            url: "https://metaverse.yccdl.cn/admin/organization/deleteAdminUser",
            method: 'post',
            data: {
              id: row.id
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token"),
            },
            withCredentials: false,
          })
            .then((res) => {
              console.log(res);
              this.$Modal.confirm({
                title: res.data.msg,
                content: '',
                onOk: () => {
                  if (res.data.code == 200) {
                    this.data = [];
                    this.research();
                  }
                }
              })
            })
            .catch((res) => {
              // console.log(res.data.data);
            });
        }
      })
    },
    handleChangePasswordC(val) {
      this.passwordLen = val.length;
      this.addform.sale_pw = val;
    },
    handleChangePasswordCheck(val) {
      this.addform.sale_again_pw = val;
    },
    // -----------------以上为 列表操作
    getsearch() {
      this.data = [];
      this.page = 1;
      this.getAdminUserList()
    },
    // getAdminUserList(e) {
    //   this.getAdminUserList()
    // },
    research() {
      this.sale_phone = '';
      this.getAdminUserList();
    },
    changePage(e) {
      console.log(e);
      this.getAdminUserList()
    },
    getAdminUserList() {
      axios({
        url: "https://metaverse.yccdl.cn/admin/organization/getAdminUserList",
        params: {
          offset: 15,
          page: this.page,
          sale_phone: this.sale_phone,
          start_date: this.start_date,
          end_date: this.end_date
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code == 401) {
            this.$Modal.confirm({
              title: '登录过期，请重新登录',
              content: '',
              onOk: () => {
                this.$router.push({
                  path: "/login",
                });
              }
            })
            return;
          }
          this.data = res.data.data.data;
          this.last_page = res.data.data.total;
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
  }
}
</script>
<style lang="less" scoped>
.role {
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: scroll;
  padding-top: 20px;

  .search_view {
    margin: 20px;
  }

  .table_view {
    margin: 20px 40px;
  }
}
</style>