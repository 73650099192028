<template>
    <div class="logonPage">
        <div class="logon_view">
            <Tabs type="card">
                <TabPane label="个人注册" name="1" context-menu>
                    <Login ref="form" @on-submit="handleSubmit">
                        <Row>
                            <Col span="6">用户姓名：</Col>
                            <Col span="18">
                            <UserName name="username" value="aresn" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">用户电话：</Col>
                            <Col span="18">
                            <Mobile name="mobile" />
                            </Col>
                        </Row>
                        <Poptip trigger="focus" placement="right" width="400">
                            <Row>
                                <Col span="6">密码：</Col>
                                <Col span="18">
                                <Password name="password" :rules="passwordRule" placeholder="至少6位密码，区分大小写"
                                    @on-change="handleChangePassword" />
                                </Col>
                            </Row>
                            <template #content>
                                <div class="demo-register-tip">
                                    <div class="demo-register-tip-title" :class="passwordTip.class">
                                        强度：{{ passwordTip.strong }}
                                    </div>
                                    <Progress :percent="passwordTip.percent" hide-info :stroke-width="6"
                                        :stroke-color="passwordTip.color" />
                                    <div class="demo-register-tip-desc">
                                        请至少输入 6 个字符。请不要使用容易被猜到的密码。
                                    </div>
                                </div>
                            </template>
                        </Poptip>
                        <Row>
                            <Col span="6">确认密码：</Col>
                            <Col span="18">
                            <Password name="passwordConfirm" :rules="passwordConfirmRule" placeholder="确认密码" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">所在部门：</Col>
                            <Col span="18">
                            <Select :rules="departmentTypeRule" v-model="form.department_type"
                                style="width:100%;margin-bottom: 20px;">
                                <Option v-for="item in department_type" :value="item.type" :key="item.type">{{ item.name }}
                                </Option>
                            </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">用户职位：</Col>
                            <Col span="18">
                            <Select :rules="positionTypeRule" v-model="form.position_type"
                                style="width:100%;margin-bottom: 20px;" @on-change="changePosition">
                                <Option v-for="item in position_type" :value="item.type" :key="item.type">{{ item.name }}
                                </Option>
                            </Select>
                            </Col>
                        </Row>
                        <Row v-if="form.position_type == 'staff'">
                            <Col span="6">负责人：</Col>
                            <Col span="18">
                            <Select v-model="form.superior_id" style="width:100%;margin-bottom: 20px;">
                                <Option v-for="item in superior_type" :value="item.id" :key="item.id">{{ item.sale_nickname
                                }}
                                </Option>
                            </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Checkbox v-model="single">已阅读并同意以下协议<span class="agreeOn" @click.stop="getAgree">隐私权政策</span>
                            </Checkbox>
                        </Row>
                        <Submit class="sub-btn">注册</Submit>
                    </Login>
                </TabPane>
                <TabPane label="城市频道" name="2" context-menu>
                    <Login ref="formCity" @on-submit="handleSubmitCity">
                        <Row>
                            <Col span="6">所在城市：</Col>
                            <Col span="18">
                            <div class="city_select">
                                <City v-model="searchCityid" show-suffix @on-change="changeCity" />
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row style="margin-bottom: 20px;">
                            <Col style="margin: 0 20px;">
                            <RadioGroup v-model="formCity.admin_type" type="button" @on-change="followChange">
                                <Radio v-for="item in radiofollow" :key="item.id" :label="item.id">{{ item.name }}
                                </Radio>
                            </RadioGroup>
                            </Col>
                            <Col span="12" v-if="formCity.admin_type == 'area'">
                            <Select v-model="regionId" style="width:100%;" @on-change="changeregion" not-found-text="请先选择所在城市">
                                <Option v-for="item in regiondata" :value="item.id" :key="item.id">{{ item.name }}
                                </Option>
                            </Select>

                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">详细地址：</Col>
                            <Col span="18">
                            <div class="city_select_noline">
                                <Input v-model="formCity.address"></Input>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">所属公司</Col>
                            <Col span="18">
                            <div class="city_select_noline">
                                <Input v-model="formCity.company_name"></Input>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">企业负责人：</Col>
                            <Col span="18">
                            <div class="city_select_noline">
                                <Input v-model="formCity.company_person"></Input>
                            </div>
                            </Col>
                        </Row>
                        <!-- <Row>
                            <Col span="6">管理员：</Col>
                            <Col span="18">
                            <div class="city_select_noline">
                                <Input v-model="formCity.input"></Input>
                            </div>
                            </Col>
                        </Row> -->
                        <Row>
                            <Col span="6">用户姓名：</Col>
                            <Col span="18">
                            <UserName name="username" value="aresn" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">用户电话：</Col>
                            <Col span="18">
                            <Mobile name="mobile" />
                            </Col>
                        </Row>

                        <Poptip trigger="focus" placement="right" width="400">
                            <Row>
                                <Col span="6">密码：</Col>
                                <Col span="18">
                                <Password name="password" :rules="passwordRule" placeholder="至少6位密码，区分大小写"
                                    @on-change="handleChangePasswordC" />
                                </Col>
                            </Row>
                            <template #content>
                                <div class="demo-register-tip">
                                    <div class="demo-register-tip-title" :class="passwordTip.class">
                                        强度：{{ passwordTip.strong }}
                                    </div>
                                    <Progress :percent="passwordTip.percent" hide-info :stroke-width="6"
                                        :stroke-color="passwordTip.color" />
                                    <div class="demo-register-tip-desc">
                                        请至少输入 6 个字符。请不要使用容易被猜到的密码。
                                    </div>
                                </div>
                            </template>
                        </Poptip>
                        <Row>
                            <Col span="6">确认密码：</Col>
                            <Col span="18">
                            <Password name="passwordConfirm" :rules="passwordConfirmRuleCity" placeholder="确认密码" />
                            </Col>
                        </Row>
                        <Row>
                            <Checkbox v-model="singleCiyt">已阅读并同意以下协议<span class="agreeOn"
                                    @click.stop="getAgree">隐私权政策</span>及<span class="agreeOn"
                                    @click.stop="getAgreeHz">合作协议</span>
                            </Checkbox>
                        </Row>
                        <Submit class="sub-btn">注册</Submit>
                    </Login>
                </TabPane>
            </Tabs>
            <Button type="success" style="width: 40%;margin-top: 20px;" @click="jumpLogin">返回登录</Button>
        </div>
        <Modal title="隐私权政策" width="800" :lock-scroll="true" v-model="modalfollow" class-name="agree_modal">
            <div class="modalUser_view">
                <div class="follow_item_logon" v-for="(item, index) in textData" :key="index">
                    {{ item }}
                </div>
            </div>
        </Modal>
        <Modal title="合作协议" width="800" :lock-scroll="true" v-model="modalfollowHz" class-name="agree_modal">
            <div class="modalUser_view">
                <div class="follow_item_logon" v-html="textHtml">
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        const validatePassCheck = (rule, value, callback) => {
            if (value !== this.$refs.form.formValidate.password) {
                callback(new Error('两次输入的密码不匹配！'));
            } else {
                callback();
            }
        };
        const validatePassCheckCity = (rule, value, callback) => {
            if (value !== this.$refs.formCity.formValidate.password) {
                callback(new Error('两次输入的密码不匹配！'));
            } else {
                callback();
            }
        };
        return {
            modalfollowHz: false,
            textHtml: '',
            textData: [
                '甲方：绿球展业（大连）有限公司  乙方：账号注册人',
                '  甲、乙双方根据国家、地方政府有关规定，在遵循平等自愿、协商一致、诚实信用的原则下，就甲方商业秘密保密事项达成如下协议。',
                '  一、保密的内容和范围 ',
                '1、软件数据涉及甲方的信息； ',
                '2、凡以直接、间接、口头或书面等形式提供涉及保密内容的行为均属泄密。',
                '3、甲方开展四联漫画大赛项目等系列相关活动所涉及的家长和孩子的个人信息、作品信息、相关延伸的信息都属于保密内容，在保密范围内。',
                '二、 双方的权利与义务、责任 ',
                '1、乙方应自觉维护甲方的利益，严格遵守本保密协议；',
                '2、乙方不得向任何单位和个人泄露甲方的任何资料信息；',
                '3、乙方不得利用所掌握的商业秘密牟取私利； ',
                '4、乙方同意并承诺，如果这些数据未经甲方许可披露给他人，所造成对甲方的直接损失，甲方有权要求乙方赔偿；当数据泄露或者甲方有足够证据证明数据已经泄漏时，甲方有权利要求乙方进行处理或者以法律方式追究乙方的民事责任。',
                '5、乙方同意并承诺，对所有保密信息予以严格保密，在未得到甲方事先许可的情况下不披露给任何其他人士或机构。',
                '6、乙方同意并承诺，未经甲方同意，乙方不可拷贝服务器上、画纸上及和甲方有关的任何数据、电话和保密范围内的信息；',
                '三、本《协议》项下的保密义务不适用于如下信息： ',
                '1、非由于乙方的原因已经为公众所知的； ',
                '2、由于法律的适用、法院或其他国家权力机关的要求而披露的信息。',
                '四、保密期限',
                '无限期保密，直至甲方宣布解密或者秘密信息实际上已经公开。',
                '五、违约责任',
                '1．乙方如违反本协议任何条款，应先一次性向甲方支付惩罚性损失费人民币伍拾万元整，有权利保留追加超过损失费人民币伍万元的其它损失费。',
                '2．如果因为乙方的违约行为造成了甲方其它损失，乙方除支付损失费外，还应承担相应的法律责任。',
                '六、其他',
                '1．乙方确认，在签署本合同前已仔细审阅过合同内容，完全了解合同各条款的法律含义。',
                '2．本协议如与双方以前的口头或书面协议有抵触，以本协议为准。本协议的修改必须采用双方同意的书面形式。',
                '3．本协议未尽事宜，按照国家法律或政府主管部门的有关规章、制度执行。',
                '七、本合同一式两份，双方各执一份，具有同等法律效力。自双方授权代表签字并盖公章之日起生效。'
            ],
            form: {
                sale_phone: '',
                sale_pw: '',
                sale_again_pw: '',
                sale_nickname: '',
                department_type: '',
                position_type: '',
                superior_id: ''
            },
            formCity: {
                sale_phone: '',
                sale_pw: '',
                sale_again_pw: '',
                sale_nickname: '',
                city_id: '',
                address: '',
                company_name: '',
                company_person: '',
                admin_type: 'organization'
            },
            passwordRule: [
                {
                    required: true, message: '密码不能为空！', trigger: 'change'
                },
                {
                    min: 6, message: '密码不能少于6位！', trigger: 'change'
                }
            ],
            passwordConfirmRule: [
                {
                    required: true, message: '确认密码不能为空！', trigger: 'change'
                },
                { validator: validatePassCheck, trigger: 'change' }
            ],
            passwordConfirmRuleCity: [
                {
                    required: true, message: '确认密码不能为空！', trigger: 'change'
                },
                { validator: validatePassCheckCity, trigger: 'change' }
            ],
            departmentTypeRule: [
                {
                    required: true, message: '所在部门不能为空！', trigger: 'change'
                }
            ],
            positionTypeRule: [
                {
                    required: true, message: '用户职位不能为空！', trigger: 'change'
                }
            ],
            // 密码长度，在密码强度提示时作为判断依据
            passwordLen: 0,
            department_type: [{
                name: '销售部',
                type: 'sell'
            }, {
                name: '企划部',
                type: 'planning'
            }, {
                name: '技术部',
                type: 'it'
            }],
            position_type: [{
                name: '员工',
                type: 'staff'
            }, {
                name: '主管',
                type: 'minister'
            }],
            superior_type: [],
            single: false,
            modalfollow: false,
            singleCiyt: false,
            radiofollow: [
                {
                    name: '城市级',
                    id: 'organization'
                }, { name: '区域级', id: 'area' }
            ],
            regiondata: [],
            regionId: '',
            searchCityid: ''
        }
    },
    created() {
        this.getMinister()
    },
    computed: {
        // 密码强度提示文案等
        passwordTip() {
            let strong = '强';
            let className = 'strong';
            let percent = this.passwordLen > 10 ? 10 : this.passwordLen;
            let color = '#19be6b';

            if (this.passwordLen < 6) {
                strong = '太短';
                className = 'low';
                color = '#ed4014';
            } else if (this.passwordLen < 10) {
                strong = '中';
                className = 'medium';
                color = '#ff9900';
            }

            return {
                strong,
                class: 'demo-register-tip-' + this.passwordLen < 6 ? 'low' : (this.passwordLen < 10 ? 'medium' : 'strong'),
                percent: percent * 10,
                color
            }
        }
    },
    methods: {
        // 23.6.5新增选择城市级或区域级
        followChange(e) {
            console.log(e)
            this.formCity.admin_type = e;
            if(this.formCity.admin_type == 'organization'){
                this.formCity.city_id = this.searchCityid
            } else {
                this.getdistList()
                this.formCity.city_id = this.regionId;
            }
        },
        changeregion(e) {
            console.log(e)
            // this.formCity.city_id = e;
        },
        getdistList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/organization/getDistrict",
                params: {
                    city_id: this.searchCityid
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    // Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 401) {
                        this.$Modal.confirm({
                            title: '登录过期，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    this.regiondata = res.data.data;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
        jumpLogin() {
            this.$router.push({
                path: "/login",
            });
        },
        // 获取保密协议
        getAgree() {
            this.modalfollow = true;
        },
        getAgreeHz() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/getStateById?id=2",
                headers: {
                    "Content-Type": "multipart/form-data",
                    // Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.textHtml = res.data.data.introduction;
                    this.modalfollowHz = true;
                })
                .catch((res) => {
                    console.log(res);
                });
        },
        handleChangePassword(val) {
            console.log(val)
            this.passwordLen = val.length;
            this.form.sale_pw = val;
        },
        handleSubmit(valid, { password, passwordConfirm, mobile, username }) {
            console.log(this.single)
            if (this.single) {


                if (valid) {
                    this.form.sale_again_pw = passwordConfirm
                    this.form.sale_phone = mobile
                    this.form.sale_nickname = username
                    console.log(this.form)
                    axios({
                        url: "https://metaverse.yccdl.cn/admin/sale/register",
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: localStorage.getItem("token"),
                        },
                        data: this.form,
                        method: 'post',
                        withCredentials: false,
                    })
                        .then((res) => {
                            console.log(res);

                            if (res.data.code == 200) {
                                this.$Modal.info({
                                    title: '提示！',
                                    content: '注册成功，前往登录'
                                })
                                this.$router.push({
                                    path: "/login",
                                });
                            } else {
                                this.$Modal.info({
                                    title: '提示！',
                                    content: res.data.msg
                                })
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                        });
                }
            } else {
                this.$Modal.info({
                    title: '提示！',
                    content: '请阅读并勾选隐私权政策协议'
                })
            }
        },
        changePosition() {
            this.form.superior_id = ''
        },
        getMinister() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/getMinister?type=sell",
                headers: {
                    "Content-Type": "multipart/form-data",
                    // Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.superior_type = res.data.data;
                })
                .catch((res) => {
                    console.log(res);
                });
        },

        handleChangePasswordC(val) {
            this.passwordLen = val.length;
            this.formCity.sale_pw = val;
        },
        // 城市频道form提交
        changeCity(e) {
            console.log(e)
            console.log(this.formCity.city_id)
            // this.searchCityid = this.formCity.city_id;
            //   if (e.c == '110000' || e.c == '120000' || e.c == '500000' || e.c == "310000") {
            //     this.province_id = e.c;
            //     this.city_id = '';
            //   } else {
            //     this.province_id = '';
            //     this.city_id = e.c;
            //   }

            this.getdistList();
        },
        handleSubmitCity(valid, { password, passwordConfirm, mobile, username }) {
            console.log(this.formCity)
            if (this.singleCiyt) {
                if (valid) {
                    this.formCity.sale_again_pw = passwordConfirm
                    this.formCity.sale_phone = mobile
                    this.formCity.sale_nickname = username
                    console.log(this.formCity)
                    this.formCity.city_id = this.formCity.admin_type == 'area'?this.regionId:this.searchCityid
                    axios({
                        url: "https://metaverse.yccdl.cn/admin/organization/register",
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // Authorization: localStorage.getItem("token"),
                        },
                        data: this.formCity,
                        method: 'post',
                        withCredentials: false,
                    })
                        .then((res) => {
                            console.log(res);

                            if (res.data.code == 200) {
                                this.$Modal.info({
                                    title: '提示！',
                                    content: '注册成功，前往登录'
                                })
                                this.$router.push({
                                    path: "/login",
                                });
                            } else {
                                this.$Modal.info({
                                    title: '提示！',
                                    content: res.data.msg
                                })
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                        });
                }
            } else {
                this.$Modal.info({
                    title: '提示！',
                    content: '请阅读并勾选隐私权政策协议及合作协议'
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.logonPage {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(150deg, #4a5cc4, #72bbff);
    overflow: hidden;

    .logon_view {
        padding: 30px 60px 60px 60px;
        border-radius: 20px;
        background: #fff;
        width: 60vh;
        margin: 30vh auto;
    }

    .agreeOn {
        color: #72bbff;
    }

    .city_select {
        border-radius: 4px;
        border: 1px solid #d6d6d6;
        width: 100%;
        margin-bottom: 10px;
        padding: 6px;
    }

    .city_select_noline {
        width: 100%;
        margin-bottom: 10px;
        padding: 6px;
    }

}
</style>
<style>
.follow_item_logon {
    padding: 20px !important;
    border-bottom: 0 !important;
}

.demo-register {
    width: 400px;
    margin: 0 auto !important;
}

.demo-register .ivu-poptip,
.demo-register .ivu-poptip-rel {
    display: block;
}

.demo-register-tip {
    text-align: left;
}

.demo-register-tip-low {
    color: #ed4014;
}

.demo-register-tip-medium {
    color: #ff9900;
}

.demo-register-tip-strong {
    color: #19be6b;
}

.demo-register-tip-title {
    font-size: 14px;
}

.demo-register-tip-desc {
    white-space: initial;
    font-size: 14px;
    margin-top: 6px;
}

.ivu-poptip {
    width: 100% !important;
}

.ivu-poptip-rel {
    width: 100% !important;
}

.sub-btn {
    margin-top: 30px;
    /* padding-top: 20px; */
    border-top: 1px solid rgba(74, 92, 196, 0.4);
}

.agree_modal {
    /* height: 60vh; */
    overflow: hidden;
}

.agree_modal .modalUser_view {
    height: 60vh;
    overflow-y: scroll;
}
</style>