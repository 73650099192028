import axios from "axios";
import QS from 'qs'
// 创建axios实例（初始化请求数据）
const http = axios.create({
    // baseURL: ' https://metaverse.yccdl.cn/admin/',
    baseURL: '/api',
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    withCredentials: false,
})
//发送真正的网络请求
http.interceptors.request.use(function (config) {
    config.headers['Authorization'] = localStorage.getItem('token') ? localStorage.getItem('token') : ''
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'  //application/x-www-form-urlencoded
    return config
}, function (error) {
    console.log(error)
    return Promise.reject(error)
})
http.interceptors.response.use(response => {
    const res = response
    return res
}, function (error) {
    // this.$router.push({
    //     path: '/login',
    //   });
    console.log(error)
    return Promise.reject(error)
})
function apiAxios(method, url, params, response, noError = () => { }) {
    http({
        method: method,
        url: url,
        data: method === 'POST' || method === 'PUT' ? params : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        headers: { Authorization: '' }
    }).then(function (res) {
        response(res.data)
    }).catch(function (err) {
        if (err.response) {
            noError(err.response.data)
        }
    })
}
export default {
    get: function (url, params, response, noError) {
        return apiAxios('GET', url, params, response, noError)
    },
    post: function (url, params, response, noError) {
        return apiAxios('POST', url, QS.stringify(params), response, noError)
    }
}