// import Vue from 'vue';
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import homepage from '../view/home/home.vue';
import login from '../view/login/login.vue';
import navigation from '../components/navigation/navigation';
import logon from '../view/logonPage/logonPage';
import cashCoupon from '../view/cashCoupon/cashCoupon';
import homeCity from '../view/homeCity/homeCity';
import payVipList from '../view/payVipList/payVipList';
import finance from '../view/finance/finance';
import mechanism from '../view/mechanism/mechanism';
import role from '../view/role/role';
import workManagement from '../view/workManagement/workManagement';
import resourceAllocation from '../view/resourceAllocation/resourceAllocation';
import cityAdmin from '../view/cityAdmin/cityAdmin';
import clockpage from '../view/clockpage/clockpage';
import ballotpage from '../view/ballotpage/ballotpage';
import orderpage from '../view/orderpage/orderpage';

const routes = [
    {
        name: 'login',
        path: '/login',
        component: login
    }, {
        name: 'logon',
        path: '/logon',
        component: logon
    }, {
        path: '/',
        component: navigation,
        hidden: true,
        children: [
            {
                path: '/',
                component: homepage,
                name: '用户管理',
                type: 'sale'
            }
            , {
                path: '/cashCoupon',
                component: cashCoupon,
                name: '代金券',
                type: 'sale'
            }
            , {
                path: '/homeCity',
                component: homeCity,
                name: '用户信息',
                type: 'organization'
            }, {
                path: '/payVipList',
                component: payVipList,
                name: '购买会员管理',
                type: 'organization'
            }, {
                path: '/finance',
                component: finance,
                name: '财务管理',
                type: 'organization'
            }, {
                path: '/mechanism',
                component: mechanism,
                name: '商业渠道管理',
                type: 'organization'
            }, {
                path: '/role',
                component: role,
                name: '角色',
                type: 'organization'
            }, {
                path: '/workManagement',
                component: workManagement,
                name: '作品',
                type: 'organization'
            }, {
                path: '/resourceAllocation',
                component: resourceAllocation,
                name: '资源分配',
                type: 'organization'
            }, {
                path: '/cityAdmin',
                component: cityAdmin,
                name: '首页',
                type: 'organization'
            }, {
                path: '/clockpage',
                component: clockpage,
                name: '打卡',
                type: 'organization'
            }, {
                path: '/ballotpage',
                component: ballotpage,
                name: '加速',
                type: 'organization'
            }, {
                path: '/orderpage',
                component: orderpage,
                name: '订单',
                type: 'organization'
            }
        ]
    }

]
export default createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})