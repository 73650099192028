<template>
  <div class="home_page">
    <header-view></header-view>
    <div class="search_view">
      <Row :gutter="16">
        <Col span="6" style="display:flex">
        <div style="min-width: 120px;">学生姓名：</div>
        <Input v-model="kid_name" style="width: 440px" />
        </Col>
        <Col span="6" style="display:flex">
        <div style="min-width: 120px;">家长姓名：</div>
        <Input v-model="parent_name" style="width: 440px" />
        </Col>
        <Col span="6" style="display:flex">
        <div style="min-width: 120px;">电话号码：</div>
        <Input v-model="user_phone" style="width: 440px" />
        </Col>
        <Col span="6" style="display:flex">
        <div style="min-width: 120px;">所在学校：</div>
        <Input v-model="school_name" style="width: 440px" />
        </Col>
      </Row>
      <Row :gutter="16" style="margin-top: 20px;">
        <Col span="6" style="display: flex;"><span style="min-width: 120px;">参加活动：</span>
        <Select v-model="last_activity_id" filterable clearable style="width: 77.5%;">
          <Option v-for="item in activityList" :value="item.id" :key="item.id">{{ item.activity_title }}</Option>
        </Select>
        </Col>
        <Col span="6" style="display: flex;">
        <span style="min-width: 120px;">年龄组别：</span>
        <Select v-model="group_type" clearable style="width: 77.5%;">
          <Option v-for="item in group_List" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
        </Col>
        <Col span="6" style="display: flex;">
        <span style="min-width: 120px;">所在城市：</span>
        <div class="city_select">
          <City v-model="city_id" show-suffix @on-change="changeCity" />
        </div>
        </Col>
        <Col span="6" style="display: flex;">
        <span style="min-width: 120px;">回访日期：</span>
        <DatePicker type="date" v-model="visit_date" @on-change="dateChange" style="width: 77.5%;" />
        </Col>
      </Row>
      <Row :gutter="16" style="margin-top: 20px;">
        <Col span="12">
        </Col>
        <Col span="3">
        <Button style="width: 80%;" type="success" @click="getNewUser">获取用户</Button></Col>
        <Col span="3">
        <Button style="width: 80%;" type="warning" @click="getToday">今日回访</Button></Col>
        <Col span="3">
        <Button style="width: 80%;" type="primary" @click="getsearch">搜索</Button>
        </Col>
        <Col span="3">
        <Button style="width: 80%;" @click="research">重置</Button>
        </Col>

        <!-- <Col span="3">
        <Button style="width: 80%;" @click="exportData">导出</Button>
        </Col> -->
      </Row>
    </div>
    <div class="table_view">
      <Table :columns="columns" border :data="data">
      </Table>
      <Page @on-change="changePage" style="margin-top: 20px" page-size="15" :total="last_page" v-model="page"
        show-total />
    </div>
    <!-- 用户画像弹窗 -->
    <Modal title="用户画像" v-model="modalUser" class-name="vertical-center-modal">
      <div class="modalUser_view">
        <div class="display_view">
          培训经历：{{ training_experience }}
        </div>
        <div class="display_view">
          学业规划：{{ academic_planning }}
        </div>
        <div class="display_view">
          家长职业：{{ parent_occupation }}
        </div>
        <div class="display_view">
          所在学校是否重点：{{ userData.school_point == '1' ? '是' : '否' }}
        </div>
        <div class="display_view">
          <div style="margin-right: 20px;font-weight: 600;">消费力： {{ userData.consume_power }}</div>
          <div style="margin-right: 20px;font-weight: 600;">消费观念： {{ userData.consume_sense }}</div>
          <div style="margin-right: 20px;font-weight: 600;">用户评级： {{ userData.user_rating }}</div>
        </div>
      </div>
    </Modal>
    <!-- 新增回访弹窗 -->
    <Modal title="新增回访" v-model="modalVisit" class-name="vertical-center-modal" @on-ok="submit" @on-cancel="clearFollow">
      <div class="modalUser_view">
        <div class="display_view">
          <div style="margin-right: 40px;">家长姓名：{{ visitData.parent_name }}</div>
          <div>家长电话：{{ visitData.user_phone }}</div>
        </div>
        <div class="display_view" style="margin-top: 20px">
          <RadioGroup v-model="folllowCheck" style="margin-right: 40px;" type="button" @on-change="followChange">
            <Radio v-for="item in radiofollow" :key="item.type" :label="item.type">{{ item.name }}</Radio>
          </RadioGroup>
          <RadioGroup v-model="typeCheck" style="margin-right: 40px;" type="button">
            <Radio v-for="item in radiotype" :key="item.type" :label="item.type">{{ item.name }}</Radio>
          </RadioGroup>
        </div>
        <div class="display_view" style="margin-top: 20px" v-if="showDate">
          下次访问：
          <DatePicker type="date" v-model="follow_date" @on-change="followDateChange" style="width: 77.5%;" />
        </div>
        <div>
          回访类型：
          <Select v-model="typeCheckS" @on-change="changeType" filterable clearable style="width: 77.5%;">
            <Option v-for="(item,index) in selectTypeData" :value="index" :key="index">{{ item.name }}</Option>
          </Select>
        </div>
        <div class="display_view" style="margin-top: 20px">
          回访备注：
          <Input style="width: 77.5%;" v-model="remark" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"></Input>
        </div>
      </div>
    </Modal>
    <!-- 回访记录弹窗 -->
    <Modal title="回访记录" v-model="modalfollow" class-name="vertical-center-modal">
      <div class="modalUser_view">
        <div class="follow_item" v-for="item in visitList" :key="item.id">
          <div class="display_view" v-if="item.sale != null">
            回访人：{{ item.sale.sale_nickname }}
          </div>
          <div class="display_view">
            <div style="margin-right: 20px;">回访日期：{{ item.add_date }}</div>
            <div style="margin-right: 20px;">回访状态：{{ item.follow == '0' ? '默认' : item.follow == '1' ? '跟踪' : '不跟踪' }}
            </div>
          </div>
          <div class="display_view">
            <div style="margin-right: 20px;">回访类型：{{ item.visit_course }}</div>
            <div>{{ item.fvisit_typeollow == '0' ? '默认' : item.follow == '1' ? '破冰' : '已成单' }}</div>
          </div>
          <div class="display_view">
            备注：{{ item.remark }}
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
import { union } from 'lodash';
axios.defaults.withCredentials = true;
export default {
  data() {
    return {
      theme: 'dark',
      school_name: '',
      kid_name: '',
      parent_name: '',
      user_phone: '',
      page: 1,
      data: [],
      last_page: 0,
      columns: [{
        title: '序号',
        type: 'index',
        align: 'center',
        minWidth: 80,
      }, {
        title: '学生姓名',
        key: 'kid_name',
        minWidth: 100,
      }, {
        title: '所在学校',
        key: 'school',
        minWidth: 240,
        render: (h, params) => {
          return h('span', params.row.school == null ? '暂无' : params.row.school.schoolname)
        }
      }, {
        title: '家长姓名',
        key: 'parent_name',
        minWidth: 100,
      }, {
        title: '参加活动',
        key: 'activity',
        minWidth: 240,
        render: (h, params) => {
          return h('span', params.row.activity.activity_title)
        }
      }, {
        title: '年龄组别',
        key: 'group_type',
        minWidth: 100,
        render: (h, params) => {
          switch (params.row.group_type) {
            case '1':
              return h('span', '幼儿组');
              break;
            case '2':
              return h('span', '小学A组');
              break;
            case '3':
              return h('span', '小学B组');
              break;
            default:
              return h('span', '初中组/高中组/大学组');
              break;
          }
        }
      }, {
        title: '所在省市区',
        key: 'prov.name',
        minWidth: 200,
        render: (h, params) => {
          if (params) {
            if (params.row.prov && params.row.city) {
              return h('span', params.row.prov.name + '-' + params.row.city.name)
            } else if (params.row.prov && params.row.city && params.row.dist) {
              return h('span', params.row.prov + '-' + params.row.city + '-' + params.row.dist)
            } else if (params.row.prov) {
              return h('span', params.row.prov.name)
            }
          }
        }
      }, {
        title: '用户画像',
        key: 'prov.name',
        minWidth: 100,
        render: (h, params) => {
          return h('div', [
            h('span', {
              class: 'checkBtn',
              onClick: () => {
                this.showUserPortrait(params.row)
              }
            }, '查看')
          ])
        }
      }, {
        title: '回访次数',
        key: 'visit_count',
        minWidth: 100,
      }, {
        title: '回访记录',
        key: 'user_id',
        minWidth: 220,
        render: (h, params) => {
          return h('div', [
            h('span', {
              class: 'checkBtn',
              onClick: () => {
                this.getVisit(params.row)
              }
            }, '查看记录'),
            h('span', {
              class: 'chceBtn_g',
              onClick: () => {
                this.setNewVisit(params.row)
              }
            }, '新增回访')
          ])
        }
      }
        //   , {
        //   title: '回访方式',
        //   key: 'type',
        //   minWidth: 140,
        //   render: (h, params) => {
        //     let data = this.selectTypeData;
        //     return h('div', [
        //       h('Select',
        //         {
        //           props: {
        //             value: this.data[params.index].id,
        //           },
        //           on: {
        //             'on-change': (value) => {
        //               console.log(value)
        //               this.typeCheck = value;
        //             }
        //           },
        //         },
        //         data.map(item => {
        //           return [h(
        //             'Option',
        //             {
        //               props: {
        //                 value: item.type,
        //                 key: item.type
        //               }
        //             }, item.name
        //           )]
        //         }),
        //       ),
        //       h('span',
        //         {
        //           class: 'chceBtn_g',
        //           onClick: () => {
        //             this.changeType(params.row)
        //           }
        //         }, '确定')
        //     ])
        //   }
        // }
      ],
      last_activity_id: '',
      activityList: [],
      group_List: [{
        name: '幼儿组',
        value: '1'
      }, {
        name: '小学A组',
        value: '2'
      }, {
        name: '小学B组',
        value: '3'
      }, {
        name: '初中组/高中组/大学组',
        value: '4'
      }],
      group_type: '',
      city_id: '',
      province_id: '',
      pxjl_array: [{
        name: '美术培训',
        value: '1'
      },
      {
        name: '早教培训',
        value: '2'
      },
      {
        name: '音乐培训',
        value: '3'
      },
      {
        name: '没有',
        value: '4'
      }, {
        name: '未知',
        value: '0'
      }
      ],
      xygh_array: [{
        name: '艺术培训',
        value: '1'
      },
      {
        name: '体育培训',
        value: '2'
      },
      {
        name: '其他',
        value: '3'
      },
      {
        name: '没有',
        value: '4'
      }, {
        name: '未知',
        value: '0'
      }
      ],
      occupation_array: [{
        name: '个人做生意',
        value: '1'
      },
      {
        name: '私人企业主',
        value: '2'
      },
      {
        name: '医生 律师 高管等',
        value: '3'
      },
      {
        name: '工人 服务员',
        value: '4'
      },
      {
        name: '其他',
        value: '5'
      }, {
        name: '未知',
        value: '0'
      }
      ],
      modalUser: false,
      academic_planning: '',
      training_experience: '',
      parent_occupation: '',
      userData: {},
      visit_date: '',
      modalVisit: false,
      radiotype: [{
        name: '破冰',
        type: '1'
      }, {
        name: '已成单',
        type: '2'
      }],
      radiofollow: [{
        name: '跟踪',
        type: '1'
      }, {
        name: '不跟踪',
        type: '2'
      }],
      typeCheck: '0',
      typeCheckS: '0',
      folllowCheck: '0',
      showDate: false,
      follow_date: '',
      visitData: {},
      remark: '',
      visitList: [],
      modalfollow: false,
      selectTypeData: [{
        name: '微信',
        type: '1'
      }, {
        name: '电话',
        type: '2'
      }],
      typeCheck: 0,
      modelCode: false,
      codeImg: ''
    }
  },
  components: {
    'header-view': header
  },
  created() {
    this.data = [];
    if (localStorage.getItem("token")) {
      this.getUserList()
      this.getactivityList()
    } else {
      this.$router.push({
        path: "/login",
      });
    }

  },
  mounted() {

  },
  methods: {
    // 更改回访类型
    changeType(row) {
      console.log(row)
      console.log(this.typeCheck)
    },
    getsearch() {
      this.data = [];
      this.page = 1;
      this.getUserList()
    },
    research() {
      this.getClear()
      this.visit_date = '';
      this.getUserList();
    },
    getClear() {
      this.data = [];
      this.page = 1;
      this.user_phone = '';
      this.kid_name = '';
      this.parent_name = '';
      this.school_name = '';
      this.last_activity_id = ''
      this.city_id = '';
      this.group_type = '';
    },
    changeCity(e) {
      console.log(e)
      if (e.c == '110000' || e.c == '120000' || e.c == '500000' || e.c == "310000") {
        this.province_id = e.c;
        this.city_id = '';
      } else {
        this.province_id = '';
        this.city_id = e.c;
      }
    },
    changePage(e) {
      console.log(e);
      this.getUserList()
    },
    // 获取用户
    getNewUser() {

      axios({
        url: "https://metaverse.yccdl.cn/admin/sale/getNewUser",
        method: 'post',
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code == 401) {
            this.$Modal.confirm({
              title: '申请无效，请重新登录',
              content: '',
              onOk: () => {
                this.$router.push({
                  path: "/login",
                });
              }
            })
            return;
          }
          this.getUserList()
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
    getToday() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      this.visit_date = y + '-' + m + '-' + d;
      // console.log(this.visit_date)
      this.getClear()
      this.getUserList()
    },
    dateChange(e) {
      console.log(e)
      this.data = []
      this.page = e;
      this.visit_date = e;
    },
    getUserList() {
      axios({
        url: "https://metaverse.yccdl.cn/admin/sale/getUserList",
        params: {
          user_phone: this.user_phone,
          kid_name: this.kid_name,
          parent_name: this.parent_name,
          group_type: this.group_type,
          last_activity_id: this.last_activity_id,
          school_name: this.school_name,
          province_id: this.province_id,
          city_id: this.city_id,
          district_id: '',
          page: this.page,
          visit_date: '',
          offset: 15,
          visit_date: this.visit_date
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
          // Origin: '127.0.0.1:8080'
        },
        // withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code == 401) {
            this.$Modal.confirm({
              title: '登录过期，请重新登录',
              content: '',
              onOk: () => {
                this.$router.push({
                  path: "/login",
                });
              }
            })
            return;
          }
          this.data = res.data.data.data;
          console.log(this.data)
          this.last_page = res.data.data.total;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    // 获取活动
    getactivityList() {
      axios({
        url: "https://metaverse.yccdl.cn/admin/sale/getactivityList",
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          // console.log(res);
          this.activityList = res.data.data;
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
    // 获取用户画像
    showUserPortrait(row) {
      console.log(row)
      console.log(row.parent_occupation)
      this.userData = row;
      // 培训经历
      this.pxjl_array.map(item => {
        if (item.value == row.training_experience) {
          this.training_experience = item.name
        }
      })
      // 家长职业
      console.log(row.training_experience)
      this.occupation_array.map(item => {
        if (item.value == row.training_experience) {
          console.log(item)
          this.parent_occupation = item.name
        }
      })
      // 学业规划
      this.xygh_array.map(item => {
        if (item.value == row.training_experience) {
          this.academic_planning = item.name
        }
      })
      this.modalUser = true;
    },
    // 查看回访记录
    getVisit(row) {
      console.log(row)
      this.visitList = [];
      axios({
        url: "https://metaverse.yccdl.cn/admin/sale/getVisitList?user_id=" + row.user_id,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          this.visitList = res.data.data;
          if (res.data.data != '') {
            this.modalfollow = true;
          } else {
            alert('暂无记录')
          }
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
    // 新增回访
    setNewVisit(row) {
      console.log(row)
      this.visitData = row;
      this.modalVisit = true;
    },
    followChange(e) {
      console.log(e)
      if (e == 1) {
        this.showDate = true;
      } else {
        this.follow_date = '';
        this.showDate = false;
      }
    },
    followDateChange(e) {
      this.follow_date = e;
    },
    // 清空
    clearFollow() {
      this.follow_date = '';
      this.showDate = false;
      this.remark = '';
      this.typeCheck = '0';
      this.folllowCheck = '0';
      this.visitData = {};
      this.modalVisit = false;
    },
    // 提交回访
    submit() {
      // console.log(this.follow_date)
      // console.log(this.remark)
      // console.log(this.typeCheck)
      // console.log(this.folllowCheck)
      // console.log(this.visitData.user_id)
      axios({
        url: "https://metaverse.yccdl.cn/admin/sale/setVisit",
        method: 'post',
        data: {
          user_id: this.visitData.user_id,
          follow: this.folllowCheck,
          visit_type: this.typeCheck,
          visit_date: this.follow_date,
          remark: this.remark,
          visit_course: this.selectTypeData[this.typeCheckS].name
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          this.$Modal.confirm({
            title: res.data.msg,
            content: '',
            onOk: () => {
            }
          })
          this.clearFollow();
          this.data = [];
          this.getUserList();
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
    // 导出功能
    // 导出数据
    exportData() {
      let choose = [];
      this.chooseData.map(item => {
        choose.push(item.activity_id)
      })
      console.log(this.chooseData)
      this.$api.get(
        "admin/getActivity",
        {
          data_type: "list",
          ids: JSON.stringify(choose)
        },
        (response) => {
          console.log(response);
          let tableData = response.data;
          // 表格表头
          this.data.forEach((item) => {
            let rowData = [];
            rowData = [item.name, item.age, item.sex, item.department];
            tableData.push(rowData);
          });
          let ws = XLSX.utils.aoa_to_sheet(tableData);
          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "数据"); // 工作簿名称
          XLSX.writeFile(wb, "导出文本.xlsx"); // 保存的文件名
        },
        (err) => {
          console.log(err);
          if (err.code == 401) {
            this.$router.push({
              path: "/login",
            });
          }
        }
      );
    },
  }
}
</script>
<style lang="less" scoped>
.home_page {
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: scroll;

  .title {
    font-weight: 600;
  }

  .search_view {
    margin: 20px;
  }

  .table_view {
    margin: 20px 40px;
  }

  .city_select {
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    width: 78%;
  }

  .btn_view {
    display: flex;
    justify-content: flex-end;
  }

}
</style>
<style>
.follow_item {
  padding: 20px;
  border-bottom: 1px solid #ececec;
}

.checkBtn {
  border-bottom: 1px solid #2d8cf0;
  color: #2d8cf0;
  cursor: pointer;
  margin-left: 20px;
}

.chceBtn_g {
  border-bottom: 1px solid #19be6b;
  color: #19be6b;
  cursor: pointer;
  margin-left: 20px;
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ivu-modal {
  top: 0;
}

.display_view {
  display: flex;
  margin-bottom: 10px;
}
</style>