<template>
    <div class="resourceAllocation">
        <header-view></header-view>
        <div class="search_view">
            <Row :gutter="16">
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">学生姓名：</div>
                <Input v-model="kid_name" style="width: 440px" />
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">家长姓名：</div>
                <Input v-model="parent_name" style="width: 440px" />
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">电话号码：</div>
                <Input v-model="user_phone" style="width: 440px" />
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">所在学校：</div>
                <Input v-model="school_name" style="width: 440px" />
                </Col>
            </Row>
            <Row :gutter="16" style="margin-top: 20px;">
                <Col span="6" style="display: flex;"><span style="min-width: 120px;">参加活动：</span>
                <Select v-model="last_activity_id" filterable clearable style="width: 77.5%;">
                    <Option v-for="item in activityList" :value="item.id" :key="item.id">{{ item.activity_title }}</Option>
                </Select>
                </Col>
                <Col span="6" style="display: flex;">
                <span style="min-width: 120px;">年龄组别：</span>
                <Select v-model="group_type" clearable style="width: 77.5%;">
                    <Option v-for="item in group_List" :value="item.value" :key="item.value">{{ item.name }}</Option>
                </Select>
                </Col>
                <Col span="6" style="display: flex;">
                <span style="min-width: 120px;">所在城市：</span>
                <div class="city_select">
                    <City v-model="city_id" show-suffix @on-change="changeCity" />
                </div>
                </Col>
                <Col span="6" style="display: flex;">
                <span style="min-width: 120px;">回访日期：</span>
                <!-- <DatePicker type="date" v-model="visit_date" @on-change="dateChange" style="width: 77.5%;" /> -->
                <DatePicker type="daterange" placement="bottom-end" placeholder="Select date" style="width: 200px"
                    @on-change="changeData" />
                </Col>
            </Row>
            <Row :gutter="16" style="margin-top: 20px;">
                <Col span="15">
                </Col>
                <!-- <Col span="3">
                <Button style="width: 80%;" type="warning" @click="getToday">今日回访</Button>
                </Col> -->
                <Col span="3">
                <Button style="width: 80%;" type="primary" @click="getsearch">搜索</Button>
                </Col>
                <Col span="3">
                <Button style="width: 80%;" @click="research">重置</Button>
                </Col>
            </Row>
        </div>
        <div class="table_view">
            <Table :columns="columns" border :data="data">
            </Table>
            <Page @on-change="changePage" style="margin-top: 20px" page-size="15" :total="last_page" v-model="page"
                show-total />
        </div>
        <!-- 新增回访弹窗 -->
        <Modal title="新增回访" v-model="modalVisit" class-name="vertical-center-modal" @on-ok="submit"
            @on-cancel="clearFollow">
            <div class="modalUser_view">
                <div class="display_view">
                    <div style="margin-right: 40px;">家长姓名：{{ visitData.parent_name }}</div>
                    <div>家长电话：{{ visitData.user_phone }}</div>
                </div>
                <div class="display_view" style="margin-top: 20px">
                    <RadioGroup v-model="folllowCheck" style="margin-right: 40px;" type="button" @on-change="followChange">
                        <Radio v-for="item in radiofollow" :key="item.type" :label="item.type">{{ item.name }}</Radio>
                    </RadioGroup>
                    <RadioGroup v-model="typeCheck" style="margin-right: 40px;" type="button">
                        <Radio v-for="item in radiotype" :key="item.type" :label="item.type">{{ item.name }}</Radio>
                    </RadioGroup>
                </div>
                <div class="display_view" style="margin-top: 20px" v-if="showDate">
                    下次访问：
                    <DatePicker type="date" v-model="follow_date" @on-change="followDateChange" style="width: 77.5%;" />
                </div>
                <div>
                    回访类型：
                    <Select v-model="typeCheckS" @on-change="changeType" filterable clearable style="width: 77.5%;">
                        <Option v-for="(item, index) in selectTypeData" :value="index" :key="index">{{ item.name }}</Option>
                    </Select>
                </div>
                <div class="display_view" style="margin-top: 20px">
                    回访备注：
                    <Input style="width: 77.5%;" v-model="remark" type="textarea"
                        :autosize="{ minRows: 2, maxRows: 5 }"></Input>
                </div>
            </div>
        </Modal>
        <!-- 回访记录弹窗 -->
        <Modal title="回访记录" v-model="modalfollow" class-name="vertical-center-modal">
            <div class="modalUser_view">
                <div class="follow_item" v-for="item in visitList" :key="item.id">
                    <div class="display_view" v-if="item.sale != null">
                        回访人：{{ item.sale.sale_nickname }}
                    </div>
                    <div class="display_view">
                        <div style="margin-right: 20px;">回访日期：{{ item.add_date }}</div>
                        <div style="margin-right: 20px;">回访状态：{{ item.follow == '0' ? '默认' : item.follow == '1' ? '跟踪' :
                            '不跟踪' }}
                        </div>
                    </div>
                    <div class="display_view">
                        <div style="margin-right: 20px;">回访类型：{{ item.visit_course }}</div>
                        <div>{{ item.fvisit_typeollow == '0' ? '默认' : item.follow == '1' ? '破冰' : '已成单' }}</div>
                    </div>
                    <div class="display_view">
                        备注：{{ item.remark }}
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
export default {
    components: {
        'header-view': header
    },
    data() {
        return {
            page: 1,
            data: [],
            last_page: 0,
            user_phone: '',
            kid_name: '',
            parent_name: '',
            group_type: '',
            last_activity_id: '',
            school_name: '',
            province_id: '',
            city_id: '',
            district_id: '',
            visit_date: '',
            activityList: [],
            group_List: [{
                name: '幼儿组',
                value: '1'
            }, {
                name: '小学A组',
                value: '2'
            }, {
                name: '小学B组',
                value: '3'
            }, {
                name: '初中组/高中组/大学组',
                value: '4'
            }],
            columns: [{
                title: '序号',
                type: 'index',
                align: 'center',
                minWidth: 80,
            }, {
                title: '学生姓名',
                key: 'kid_name',
                minWidth: 100,
            }, {
                title: '所在学校',
                key: 'school',
                minWidth: 240,
                render: (h, params) => {
                    return h('span', params.row.school == null ? '暂无' : params.row.school.schoolname)
                }
            }, {
                title: '家长姓名',
                key: 'parent_name',
                minWidth: 100,
            }, {
                title: '参加活动',
                key: 'activity',
                minWidth: 240,
                render: (h, params) => {
                    return h('span', params.row.activity.activity_title)
                }
            }, {
                title: '年龄组别',
                key: 'group_type',
                minWidth: 100,
                render: (h, params) => {
                    switch (params.row.group_type) {
                        case '1':
                            return h('span', '幼儿组');
                            break;
                        case '2':
                            return h('span', '小学A组');
                            break;
                        case '3':
                            return h('span', '小学B组');
                            break;
                        default:
                            return h('span', '初中组/高中组/大学组');
                            break;
                    }
                }
            }, {
                title: '所在省市区',
                key: 'prov.name',
                minWidth: 200,
                render: (h, params) => {
                    if (params) {
                        if (params.row.prov && params.row.city) {
                            return h('span', params.row.prov.name + '-' + params.row.city.name)
                        } else if (params.row.prov && params.row.city && params.row.dist) {
                            return h('span', params.row.prov + '-' + params.row.city + '-' + params.row.dist)
                        } else if (params.row.prov) {
                            return h('span', params.row.prov.name)
                        }
                    }
                }
            }, {
                title: '回访次数',
                key: 'visit_count',
                minWidth: 100,
            }, {
                title: '回访记录',
                key: 'user_id',
                minWidth: 220,
                render: (h, params) => {
                    return h('div', [
                        h('span', {
                            class: 'checkBtn',
                            onClick: () => {
                                this.getVisit(params.row)
                            }
                        }, '查看记录'),
                        h('span', {
                            class: 'chceBtn_g',
                            onClick: () => {
                                this.setNewVisit(params.row)
                            }
                        }, '新增回访')
                    ])
                }
            }
            ],
            modalVisit: false,
            modalfollow: false,
            radiotype: [{
                name: '破冰',
                type: '1'
            }, {
                name: '已成单',
                type: '2'
            }],
            radiofollow: [{
                name: '跟踪',
                type: '1'
            }, {
                name: '不跟踪',
                type: '2'
            }],
            typeCheck: '0',
            typeCheckS: '0',
            folllowCheck: '0',
            showDate: false,
            follow_date: '',
            visitData: {},
            remark: '',
            visitList: [],
            selectTypeData: [{
                name: '微信',
                type: '1'
            }, {
                name: '电话',
                type: '2'
            }],
            end_date: '',
            start_date: ''
        }
    },
    created() {
        this.data = [];
        if (localStorage.getItem("token")) {
            this.getUserList()
            this.getactivityList()
        } else {
            this.$router.push({
                path: "/login",
            });
        }
    },
    methods: {
        changeCity(e) {
            if (e.c == '110000' || e.c == '120000' || e.c == '500000' || e.c == "310000") {
                this.province_id = e.c;
                this.city_id = '';
            } else {
                this.province_id = '';
                this.city_id = e.c;
            }
        },
        // dateChange(e) {
        //     console.log(e)
        //     this.visit_date = e;
        // },
        changeData(e) {
            console.log(e)
            this.start_date = e[0];
            this.end_date = e[1];
            this.getUserList();
        },
        // getToday() {
        //     let date = new Date();
        //     let y = date.getFullYear();
        //     let m = date.getMonth() + 1;
        //     m = m < 10 ? '0' + m : m;
        //     let d = date.getDate();
        //     d = d < 10 ? ('0' + d) : d;
        //     this.visit_date = y + '-' + m + '-' + d;
        //     // console.log(this.visit_date)
        //     this.getClear()
        //     this.getUserList()
        // },
        getsearch() {
            this.data = [];
            this.page = 1;
            this.getUserList()
        },
        getClear() {
            this.data = [];
            this.page = 1;
            this.user_phone = '';
            this.kid_name = '';
            this.parent_name = ''; kl
            this.school_name = '';
            this.last_activity_id = ''
            this.city_id = '';
            this.group_type = '';
        },
        research() {
            this.getClear()
            this.visit_date = '';
            this.getUserList();
        },
        changePage(e) {
            console.log(e);
            this.getUserList()
        },
        getUserList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/getCodeUser",
                params: {
                    user_phone: this.user_phone,
                    kid_name: this.kid_name,
                    parent_name: this.parent_name,
                    group_type: this.group_type,
                    last_activity_id: this.last_activity_id,
                    school_name: this.school_name,
                    province_id: this.province_id,
                    city_id: this.city_id,
                    district_id: '',
                    page: this.page,
                    offset: 15,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    visit_date: ''
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                    // Origin: '127.0.0.1:8080'
                },
                // withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 401) {
                        this.$Modal.confirm({
                            title: '登录过期，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    this.data = res.data.data.data;
                    this.last_page = res.data.data.total;
                })
                .catch((res) => {
                    console.log(res);
                });
        },
        // 获取活动
        getactivityList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/getactivityList",
                headers: {
                    "Content-Type": "multipart/form-data",
                    // Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    // console.log(res);
                    this.activityList = res.data.data;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },

        // 查看回访记录
        getVisit(row) {
            console.log(row)
            this.visitList = [];
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/getVisitList?user_id=" + row.user_id,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.visitList = res.data.data;
                    if (res.data.data != '') {
                        this.modalfollow = true;
                    } else {
                        alert('暂无记录')
                    }
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },

        followChange(e) {
            console.log(e)
            if (e == 1) {
                this.showDate = true;
            } else {
                this.follow_date = '';
                this.showDate = false;
            }
        },
        followDateChange(e) {
            this.follow_date = e;
        },
        // 新增回访
        setNewVisit(row) {
            console.log(row)
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/setUserRead",
                method: 'post',
                data: {
                    user_id: row.user_id
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 200) {
                        this.visitData = row;
                        this.modalVisit = true;
                    } else {
                        this.$Modal.confirm({
                            title: '提示',
                            content: res.data.msg,
                            onOk: () => {
                            }
                        })
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        },
        // 提交回访

        // 更改回访类型
        changeType(row) {
            console.log(row)
            console.log(this.typeCheck)
        },
        submit() {
            // console.log(this.follow_date)
            // console.log(this.remark)
            // console.log(this.typeCheck)
            // console.log(this.folllowCheck)
            // console.log(this.visitData.user_id)
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/setVisit",
                method: 'post',
                data: {
                    user_id: this.visitData.user_id,
                    follow: this.folllowCheck,
                    visit_type: this.typeCheck,
                    visit_date: this.follow_date,
                    remark: this.remark,
                    visit_course: this.selectTypeData[this.typeCheckS].name
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.$Modal.confirm({
                        title: res.data.msg,
                        content: '',
                        onOk: () => {
                        }
                    })
                    this.clearFollow();
                    this.data = [];
                    this.getUserList();
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
        // 清空
        clearFollow() {
            this.follow_date = '';
            this.showDate = false;
            this.remark = '';
            this.typeCheck = '0';
            this.folllowCheck = '0';
            this.visitData = {};
            this.modalVisit = false;
        },
    }
}
</script>
<style lang="less" scoped>
.resourceAllocation {
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: scroll;

    .title {
        font-weight: 600;
    }

    .search_view {
        margin: 20px;
    }

    .table_view {
        margin: 20px 40px;
    }

    .city_select {
        border-radius: 4px;
        border: 1px solid #d6d6d6;
        width: 78%;
    }

    .btn_view {
        display: flex;
        justify-content: flex-end;
    }
}
</style>