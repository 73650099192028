<template>
    <div class="payVipList">
        <header-view></header-view>
        <div class="search_view" style="margin:40px 20px">
            <Row :gutter="16">
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">会员星级：</div>
                <Select v-model="vip_type" filterable clearable style="width: 77.5%;">
                    <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                </Col>
            </Row>
            <Row :gutter="16" style="margin-top: 20px;">
                <Col span="18">
                </Col>
                <Col span="3">
                <Button style="width: 80%;" type="primary" @click="getsearch">搜索</Button>
                </Col>
                <Col span="3">
                <Button style="width: 80%;" @click="research">重置</Button>
                </Col>
            </Row>
        </div>
        <div class="table_view">
            <Table :columns="columns" border :data="data">
                <template slot="title1">{{ title1 }}</template>
            </Table>
            <Page @on-change="changePage" style="margin-top: 20px" page-size="15" :total="last_page" v-model="page"
                show-total />
        </div>
        <Modal title="用户详情" v-model="modalfollow" class-name="vertical-center-modal">
            <div class="modalUser_view">
                <div class="display_view">
                    <div style="min-width: 100px;">学生姓名：</div><Input v-model="user.kid_name"></Input>
                </div>
                <div class="display_view">
                    <div style="min-width: 100px;">联系电话：</div><Input v-model="user.user_phone"></Input>
                </div>
                <div class="display_view">
                    <div style="min-width: 100px;">家长姓名：</div> <Input v-model="user.user_truename"></Input>
                </div>
                <div class="display_view">
                    <div style="min-width: 100px;">学生性别：</div>
                    <Select v-model="user.kid_sex" filterable clearable style="width: 77.5%;">
                        <Option v-for="item in sexType" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </div>
                <div class="display_view">
                    <div style="min-width: 100px;">出生日期：</div>
                    <DatePicker type="date" v-model="user.kid_january" @on-change="dateChange" style="width: 77.5%;" />
                </div>
                <div class="display_view">
                    <div style="min-width: 100px;">所在城市:</div>
                    <City style="border-radius: 3px;border: 1px solid #ccc;padding: 6px 7px;" v-model="user.city_id"
                        show-suffix @on-change="changeCity" />
                </div>
                <div class="display_view">
                    <div style="min-width: 100px;">所在地区:</div>
                    <Select v-model="user.code" filterable clearable style="width: 77.5%;" not-found-text="暂无数据，请先选择城市">
                        <Option v-for="item in distList" :value="item.id" :key="item.id" @on-change="cityChange">{{
                            item.name }}</Option>
                    </Select>
                </div>
                <div class="display_view">
                    <div style="min-width: 100px;">学校：</div>
                    <Select v-model="user.school_id" filterable clearable style="width: 77.5%;">
                        <Option v-for="item in schoolList" :value="item.id" :key="item.id" @on-change="schoolChange">{{
                            item.schoolname }}</Option>
                    </Select>
                </div>
                <div class="display_view">
                    <div style="min-width: 100px;">详细地址：</div>
                    <Input v-model="user.user_detailed" type="textarea"></Input>
                </div>
                <div class="display_view">
                    <Button type="primary" @click="submit()">提交</Button>
                    <Button @click="modalfollow = false" style="margin-left: 8px">取消</Button>
                </div>
            </div>
            <template v-slot:footer>
                <div></div>
            </template>
        </Modal>
    </div>
</template>
<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
import { union } from 'lodash';
axios.defaults.withCredentials = true;
export default {
    components: {
        'header-view': header
    },
    data() {
        return {
            sexType: [
                {
                    id: '0',
                    name: '女'
                }, {
                    id: '1',
                    name: '男'
                }
            ],
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    align: 'center',
                    minWidth: 80,
                }, {
                    title: '用户姓名',
                    key: 'name',
                    minWidth: 140,
                    render: (h, params) => {
                        return h('div', [
                            h('span', {
                                class: 'checkBtn',
                                onClick: () => {
                                    this.getUser(params.row)
                                }
                            }, params.row.user.kid_name != null && params.row.user.kid_name != '' ? params.row.user.kid_name : '暂无用户姓名')
                        ])
                    }
                }, {
                    title: '所在地区',
                    key: 'address',
                    minWidth: 240,
                    render: (h, params) => {
                        if (params.row.user) {
                            return h('span', params.row.user.user_address)
                        }
                    }
                }, {
                    title: '购买日期',
                    key: 'pay_date',
                    minWidth: 240
                }, {
                    title: '会员类型',
                    key: 'vip_type',
                    minWidth: 240,
                    render: (h, params) => {
                        if (params) {
                            if (params.row.vip_type == 'common') {
                                return h('span', '1星志愿者')
                            } else if (params.row.vip_type == 'silver') {
                                return h('span', '3星志愿者')
                            } else {
                                return h('span', '7星志愿者')
                            }
                        }
                    }
                }, {
                    title: '支付金额',
                    key: 'total_fee',
                    minWidth: 120,
                    render: (h, params) => {
                        if (params) {
                            return h('span', params.row.total_fee + '元')
                        }
                    }
                }
            ],
            data: [],
            page: 1,
            last_page: 0,
            modalfollow: false,
            dataUser: {},
            user: {
                kid_name: '',
                user_truename: '',
                kid_sex: '',
                kid_january: '',
                user_address: '',
                schoolName: '',
                user_phone: '',
                city_id: '',
                user_id: '',
                code: '',
                school_id: '',
                user_detailed: ''
            },
            typeList: [{
                id: 'common',
                name: '1星志愿者'
            }, {
                id: 'silver',
                name: '3星志愿者'
            }, {
                id: 'platinum',
                name: '7星志愿者'
            }],
            vip_type: '',
            distList: [],
            schoolList: []
        }
    },
    created() {
        if (localStorage.getItem("token")) {
            this.getUserList();
        } else {
            this.$router.push({
                path: "/login",
            });
        }
    },
    methods: {
        changePage(e) {
            console.log(e);
            this.getUserList()
        },
        getsearch() {
            this.data = [];
            this.page = 1;
            this.getUserList()
        },
        research() {
            this.vip_type = '';
            this.getUserList();
        },
        getUserList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/organization/getPayVipList",
                params: {
                    page: this.page,
                    offset: 15,
                    vip_type: this.vip_type
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                // withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 401) {
                        this.$Modal.confirm({
                            title: '登录过期，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    this.data = res.data.data.data;
                    this.last_page = res.data.data.total;
                    if (this.data[0].sale != undefined) {
                        this.columns[6] = {
                            title: '成单业务员',
                            key: 'address',
                            minWidth: 240,
                            render: (h, params) => {
                                if (params.row.sale) {
                                    return h('span', params.row.sale != null && params.row.sale.sale_nickname ? params.row.sale.sale_nickname : '暂无')
                                }
                            }
                        }
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        },
        getUser(row) {
            console.log(row)
            console.log(row.user)
            this.user = row.user;
            let data = row.user
            console.log(data.kid_january)
            this.user = {
                kid_name: data.kid_name,
                user_truename: data.user_truename,
                kid_sex: data.kid_sex,
                kid_january: data.kid_january,
                user_address: data.user_address,
                schoolName: data.school != null ? data.school.schoolname : '暂无',
                user_phone: data.user_phone,
                city_id: data.city_id,
                user_id: row.user_id,
                code: data.district_id,
                school_id: data.school_id,
                user_detailed: data.user_detailed
            },
                this.searchCityid = data.city_id;
            console.log(this.user.kid_january)
            this.getSchool();
            this.getdistList();
            this.modalfollow = true;
        },
        // 编辑提交
        submit() {
            console.log(this.user.kid_january)
            let time = new Date(this.user.kid_january);
            let newTime = time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate();
            console.log(newTime)
            let form = {
                user_id: this.user.user_id,
                kid_name: this.user.kid_name,
                parent_name: this.user.user_truename,
                kid_sex: this.user.kid_sex,
                kid_january: newTime,
                code: this.user.code,
                school_id: this.user.school_id,
                user_address: this.user.user_address,
                user_detailed: this.user.user_detailed
            }
            axios({
                url: "https://metaverse.yccdl.cn/admin/organization/updatePayVip",
                method: 'post',
                data: form,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.$Modal.confirm({
                        title: res.data.msg,
                        content: '',
                        onOk: () => {
                            if (res.data.code == 200) {
                                this.modalfollow = false;
                                this.research();
                            }
                        }
                    })
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
        //  编辑操作
        dateChange(e) {
            console.log(e)
            this.user.kid_january = e;
        },
        schoolChange(e) {
            this.user.schoolName = e;
        },
        cityChange(e) {
            this.user.code = e;
        },
        changeCity(e) {
            console.log(e)
            console.log(this.user.city_id)
            this.getSchool()
            this.getdistList()
        },
        getSchool() {

            console.log(this.user.city_id)
            axios({
                url: "https://metaverse.yccdl.cn/admin/organization/getSchool",
                params: {
                    city_id: this.user.city_id
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.schoolList = res.data.data;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
        getdistList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/organization/getDistrict",
                params: {
                    city_id: this.user.city_id
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.distList = res.data.data;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
    }
}
</script>
<style lang="less" scoped>
.payVipList {
    .table_view {
        margin: 20px 40px;
    }
}
</style>