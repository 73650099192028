<template>
    <div class="finance">
        <header-view></header-view>
        <div class="search_view">
            <Row :gutter="16">
                <Col span="2" style="display:flex">
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">查询日期：</div>
                <DatePicker type="daterange" placement="bottom-end" placeholder="Select date" style="width: 200px"
                    @on-change="changeData" />
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;font-size: 20px;">{{ start_date == '' || end_date == '' ? '总销售额：' : start_date
                    + '-' + end_date + '销售额：' }}
                    <span style="font-weight: 600;">{{ sum.pay_total + ' ' }}元</span>
                </div>
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;font-size: 20px;">{{ start_date == '' || end_date == '' ? '总分润：' : start_date +
                    '-' + end_date + '分润：' }}
                    <span style="font-weight: 600;">{{ sum.share_total + ' ' }}元</span>
                </div>
                </Col>
            </Row>
            <Row :gutter="16" style="margin-top: 20px;">
                <Col span="2" style="display:flex">
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;font-size: 20px;">成单数量
                    <span style="font-weight: 600;">{{ total + ' ' }}笔</span>
                </div>
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;font-size: 20px;">分润占比
                    <span style="font-weight: 600;">{{ share_rate }}%</span>
                </div>
                </Col>
            </Row>
            <Row :gutter="16" style="margin-top: 20px;">
                <Col span="21">
                </Col>
                <Col span="3">
                <Button style="width: 80%;" @click="research">重置</Button>
                </Col>
            </Row>
        </div>
        <div class="table_view">
            <Table :columns="columns" border :data="data">
            </Table>
            <Page @on-change="changePage" style="margin-top: 20px" page-size="15" :total="last_page" v-model="page"
                show-total />
        </div>
    </div>
</template>

<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
import { render } from '@vue/runtime-dom';
export default {
    components: {
        'header-view': header
    },
    data() {
        return {
            start_date: '',
            end_date: '',
            sum: {
                pay_total: 0,
                share_total: 0
            },
            last_page: 0,
            data: [],
            page: 1,
            columns: [{
                title: '序号',
                type: 'index',
                align: 'center',
                minWidth: 80,
            }, {
                title: '商品名',
                align: 'center',
                key: 'describe',
                minWidth: 80,
            }, {
                title: '销售额',
                align: 'center',
                key: 'pay_total',
                minWidth: 80,
            }, {
                title: '销售分润',
                align: 'center',
                key: 'share_total',
                minWidth: 80,
            }, {
                title: '分润占比',
                align: 'center',
                key: 'share_total',
                minWidth: 80,
                render: (h, params) => {
                    if (params.row.pay_total && params.row.share_total) {
                        return h('span', ((params.row.share_total / params.row.pay_total) * 100).toFixed(2) + '%')
                    }
                }
            }, {
                title: '入账日期',
                align: 'center',
                key: 'add_date',
                minWidth: 80,
            }],
            total: 0,
            share_rate: 0
        }
    },
    created() {
        this.data = [];
        if (localStorage.getItem("token")) {
            this.getShareTotalList();
        } else {
            this.$router.push({
                path: "/login",
            });
        }
    },
    methods: {
        changePage(e) {
            console.log(e);
            this.getShareTotalList()
        },
        research() {
            this.start_date = '';
            this.end_date = '';
            this.getShareTotalList();
        },
        changeData(e) {
            console.log(e)
            this.start_date = e[0];
            this.end_date = e[1];
            this.getShareTotalList();
        },
        getShareTotalList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/getShareTotalList",
                params: {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    offset: 15,
                    page: this.page
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    if (res.data.code == 401) {
                        this.$Modal.confirm({
                            title: '登录过期，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    // console.log(res.data.data);
                    let data = res.data.data;
                    this.sum = data.sum;
                    this.data = data.data.data;
                    this.share_rate = res.data.data.share_rate;
                    this.total = res.data.data.data.total;
                    this.last_page = data.data.total;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        }
    }
}
</script>

<style lang="less" scoped>
.finance {
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: scroll;

    .search_view {
        margin: 20px;
    }

    .table_view {
        margin: 20px 40px;
    }
}
</style>