<template>
    <div class="cashCoupon">
        <header-view></header-view>
        <div class="table_view">
            <Table :columns="columns" border :data="data">
            </Table>
            <Page @on-change="changePage" style="margin-top: 20px" page-size="15" :total="last_page" v-model="page"
                show-total />
        </div>
        <Modal title="优惠券码" v-model="modalfollow" class-name="modal_card">
            <img :src="cardUrl" alt="">
        </Modal>
    </div>
</template>
<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
export default {
    data() {
        return {
            columns: [{
                title: '序号',
                type: 'index',
                align: 'center',
                minWidth: 80,
            }, {
                title: '卡券名称',
                key: 'coupon_name',
                minWidth: 160,
            }, {
                title: '使用期限',
                key: 'start_time',
                minWidth: 240,
                render: (h, params) => {
                    return h('span', params.row.start_time + '至' + params.row.end_time)
                }
            }, {
                title: '发放数量',
                key: 'coupon_number',
                minWidth: 160,
            }, {
                title: '抵值金额',
                key: 'derate_total',
                minWidth: 160,
            }, {
                title: '操作',
                key: 'id',
                minWidth: 100,
                render: (h, params) => {
                    return h('div', [
                        h('span', {
                            class: 'checkBtn',
                            onClick: () => {
                                this.getCouponCode(params.row)
                            }
                        }, '查看')
                    ])
                }
            }],
            data: [],
            page: 1,
            last_page: 0,
            modalfollow: false,
            cardUrl: ''
        }
    },
    components: {
        'header-view': header
    },
    created() {
        this.data = [];
        if (localStorage.getItem("token")) {
            this.getCashCouponList()
        } else {
            this.$router.push({
                path: "/login",
            });
        }
    },
    methods: {
        changePage(e) {
            this.getCashCouponList()
        },
        getCashCouponList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/getCashCouponList",
                params: {
                    page: this.page,
                    offset: 15
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    if (res.data.code == 401) {
                        localStorage.clear();
                        this.$Modal.confirm({
                            title: '登录，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    this.data = res.data.data.data;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
        // 获取卡券
        getCouponCode(row) {
            console.log(row)
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/getCouponCode",
                params: {
                    coupon_id: row.id
                },
                method: 'post',
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.cardUrl = res.data.data.base64_img;
                    this.modalfollow = true;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        }
    }
}
</script>
<style lang="less" scoped>
.cashCoupon {
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: scroll;

    .table_view {
        margin: 20px 40px;
    }

    .checkBtn {
        border-bottom: 1px solid #2d8cf0;
        color: #2d8cf0;
        cursor: pointer;
    }
}
</style>
<style lang="less">
.modal_card {
    display: block;

    img {
        width: 100%;
    }
}
</style>