<template>
    <div class="checkTime">

    </div>
</template>
<script>
export default {
    data() {
        return {
            mouseCheck: false
        }
    },
    created() {
        let time = setInterval(() => {
            console.log(this.mouseCheck)
            if (!this.mouseCheck) {
                localStorage.clear();
                this.mouseCheck = false;
                this.$Modal.confirm({
                    title: '检测到您超时微操作，请重新登录',
                    content: '',
                    onOk: () => {
                        this.$router.push({
                            path: "/login",
                        });
                    },
                    onCancel:()=>{
                        this.$router.push({
                            path: "/login",
                        });
                    }
                })
                clearTimeout(time);
            }
        }, 1800000);
    },
    mounted() {
        window.addEventListener('mousedown', this.onmousedown);
    },
    methods: {
        onmousedown(e) {
            this.mouseCheck = true;
        }
    }
}
</script>
<style></style>