<template>
    <div class="workManagement">
        <header-view></header-view>
        <div class="search_view">
            <Row :gutter="16">
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">作者姓名：</div>
                <Input v-model="join_name" style="width: 440px" />
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">家长电话：</div>
                <Input v-model="join_parentphone" style="width: 440px" />
                </Col>
                <Col span="6" style="display: flex;"><span style="min-width: 120px;">所在学校：</span>
                <Select v-model="school_id" filterable clearable style="width: 77.5%;">
                    <Option v-for="item in schoolList" :value="item.id" :key="item.id">{{ item.schoolname }}</Option>
                </Select>
                </Col>
                <Col span="6" style="display: flex;">
                <span style="min-width: 120px;">投稿日期：</span>
                <DatePicker type="daterange" placement="bottom-end" placeholder="Select date" style="width: 200px"
                    @on-change="changeData" />
                </Col>
            </Row>
            <Row :gutter="16" style="margin-top: 20px;">
                <Col span="18">
                </Col>
                <Col span="3">
                <Button style="width: 80%;" type="primary" @click="getsearch">搜索</Button>
                </Col>
                <Col span="3">
                <Button style="width: 80%;" @click="research">重置</Button>
                </Col>
            </Row>
        </div>
        <div class="table_view">
            <Table :columns="columns" border :data="data">
            </Table>
            <Page @on-change="changePage" style="margin-top: 20px" page-size="15" :total="last_page" v-model="page"
                show-total />
        </div>
        <!-- 查看图片 -->
        <Modal title="" v-model="modelCode" class-name="vertical-center-modal">
            <img style="width: 100%;height: 100%;" :src="codeImg" alt="">
        </Modal>
    </div>
</template>
<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
export default {
    components: {
        'header-view': header
    },
    data() {
        return {
            visit_date: '',
            schoolList: [],
            school_id: '',
            join_parentphone: '',
            join_name: '',
            page: 1,
            data: [],
            last_page: 1,
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    align: 'center',
                    minWidth: 50,
                }, {
                    title: '作者姓名',
                    key: 'join_name',
                    align: 'center',
                    minWidth: 80,
                }, {
                    title: '学校',
                    key: 'join_name',
                    align: 'center',
                    minWidth: 120,
                    render: (h, params) => {
                        if (params) {
                            return h('span', params.row.school != null ? params.row.school.schoolname : '暂无')
                        }
                    }
                }, {
                    title: '指导老师',
                    key: 'join_teachername',
                    align: 'center',
                    minWidth: 80
                }, {
                    title: '年级组别',
                    key: 'class_type',
                    align: 'center',
                    minWidth: 100,
                    render: (h, params) => {
                        if (params.row.class_type != null) {
                            if (params.row.class_type == '1') {
                                return h('span', '幼儿组')
                            } else if (params.row.class_type == '2') {
                                return h('span', '小学A组')
                            } else if (params.row.class_type == '3') {
                                return h('span', '小学B组')
                            } else if (params.row.class_type == '4') {
                                return h('span', '中学组')
                            } else {
                                return h('span', '其他')
                            }
                        } else {
                            return h('span', '其他')
                        }
                    }
                }, {
                    title: '作品名',
                    key: 'join_productname',
                    align: 'center',
                    minWidth: 100,
                }, {
                    title: '投稿时间',
                    key: 'add_date',
                    align: 'center',
                    minWidth: 120,
                }, {
                    title: '参加活动',
                    key: 'activity_title',
                    align: 'center',
                    minWidth: 160,
                    render: (h, params) => {
                        if (params) {
                            return h('span', params.row.activity.activity_title)
                        }
                    }
                }, {
                    title: '参赛类别',
                    key: 'activity_title',
                    align: 'center',
                    minWidth: 100,
                    render: (h, params) => {
                        if (params.row.prize != null) {
                            return h('span', params.row.prize.prize_name)
                        }
                    }
                }, {
                    title: '作品图片',
                    key: 'oss_img',
                    align: 'center',
                    minWidth: 240,
                    render: (h, params) => {
                        return h('img', {
                            src: params.row.oss_img,
                            style: {
                                width: '220px',
                                height: '160px',
                                margin: '20px'
                            },
                            onClick: () => {
                                this.modelCode = true;
                                this.codeImg = params.row.oss_img;
                            }
                        })
                    }
                }
            ],
            codeImg: '',
            modelCode: false,
            end_date: '',
            start_date: ''
        }
    },
    created() {

        if (localStorage.getItem("token")) {
            this.getSchool();
            this.getJoinList()
        } else {
            this.$router.push({
                path: "/login",
            });
        }
    },
    methods: {

        changeData(e) {
            console.log(e)
            this.start_date = e[0];
            this.end_date = e[1];
        },
        getsearch() {
            this.data = [];
            this.page = 1;
            this.getJoinList()
        },
        research() {
            this.school_id = '';
            this.join_parentphone = '';
            this.join_name = ''
            this.getJoinList();
        },
        changePage(e) {
            console.log(e);
            this.getJoinList()
        },
        getJoinList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/join/getJoinList",
                params: {
                    offset: 10,
                    page: this.page,
                    school_id: this.school_id,
                    join_parentphone: this.join_parentphone,
                    join_name: this.join_name,
                    start_date: this.start_date,
                    end_date: this.end_date
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 401) {
                        this.$Modal.confirm({
                            title: '登录过期，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    this.data = res.data.data.data;
                    this.last_page = res.data.data.total;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
        getSchool() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/organization/getSchool",
                params: {
                    city_id: localStorage.getItem('user_cityId')
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.schoolList = res.data.data;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
    }
}
</script>
<style lang="less" scoped>
.workManagement {

    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: scroll;

    .search_view {
        margin: 20px;
    }

    .table_view {
        margin: 20px;
    }
}
</style>