<template>
  <div class="head_page">
    <!-- <navigation class="header_left"></navigation> -->
    <div class="head">
      <div v-if="backshow" class="back-body">
        <i class="el-icon-arrow-left" style="font-size:30px;line-height:40px"></i>
      </div>
      <div class="user-body">
        <Button type="primary" style="width: 200px;margin-top: 10px;" @click="getCode">获取业务码</Button>
        <el-dropdown trigger="click" @command="handleCommand">
          <span>{{ username }}</span>
          <i class="el-icon-user" style="color:#4b7bad;font-size:30px;margin:15px"></i>
          <el-dropdown-menu>
            <el-dropdown-item @click="goback">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 业务码 显示 -->
    <Modal title="业务码" v-model="modelCode" class-name="vertical-center-modal">
      <img style="width: 100%;height: 100%;" :src="codeImg" alt="">
    </Modal>
  </div>
</template>
<script>
// import navigation from '../components/navigation.vue'
import axios from 'axios';
export default {
  // components:{
  //   navigation
  // },
  // props: ["backshow", "backurl"],
  data() {
    return {
      username: "",
      codeImg: '',
      modelCode: false
    };
  },

  created() {
    this.username = localStorage.getItem("user");
  },

  methods: {
    // 返回上一页
    goback() {
      console.log(1)
      localStorage.clear
      this.$router.push({
        path: "/login",
      });
    },
    // 获取业务码
    getCode() {
      axios({
        url: "https://metaverse.yccdl.cn/admin/sale/getUserCode",
        method: 'post',
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        withCredentials: false,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.modelCode = true;
            this.codeImg = res.data.data.base64_img;
          }
        })
        .catch((res) => {
          // console.log(res.data.data);
        });
    },
  }
};
</script> 

<style scoped>
.head_page {
  display: flex;
}

.head {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgb(235, 235, 235);
  /* position: absolute; */
  background: #fff;
  right: 0;
  /* display: flex;
  justify-content: space-between; */
}

.back-body {
  width: 40px;
  height: 40px;
  margin: 10px 20px;
  float: left;
}

.user-body {
  min-width: 22%;
  height: 60px;
  float: right;
  display: flex;
  margin-right: 20px;
  justify-content: space-between;
  cursor: pointer;
}

.user-body span {
  font-size: 22px;
  color: #4b7bad;
  line-height: 60px;
}
</style>
<style></style>