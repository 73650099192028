<template>
    <div class="nav_view" ref="page">
        <div style="height:100%;width:280px;background:#515a6e;">
            <div class="nav_title">
                <span>FCC城市频道后台管理系统1.0</span>
            </div>
            <Menu :theme="theme" style="width:280px;" active-name="0">
                <MenuItem name="0" @click="jump('/cityAdmin')" v-if="admin_type!='sale'">
                首页
                </MenuItem>
                <Submenu :name="index" v-for="(item, index) in menuList" :key="index">
                    <template #title>
                        <Icon type="ios-paper" />
                        {{ item.title }}
                    </template>
                    <MenuItem :name="itemI.id" v-for="(itemI, indexI) in item.children" :key="indexI"
                        @click="jump(itemI.url)">
                    {{ itemI.title }}
                    </MenuItem>
                </Submenu>
                <!-- <MenuItem :name="item.path" v-for="(item, index) in menuList" :key="index" @click="jump(item.path)">
                {{ item.name }}</MenuItem> -->
            </Menu>
        </div>
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
        <timer></timer>
    </div>
</template>
<script>
import axios from 'axios';
import timer from '@/components/checkTime/checkTime.vue';
export default {
    components:{
        timer
    },
    data() {
        return {
            value: "",
            menuList: [],
            theme: 'dark',
            pageType: '',
            admin_type: localStorage.getItem('admin_type')
        }
    },
    created() {

    },
    mounted() {
        // this.pageType = localStorage.getItem('admin_type');
        // this.menuList = this.$router.options.routes[2].children;
        this.getmenu();
        // console.log(this.menuList)
        this.$refs.page.style.height = window.innerHeight + "px";
    },
    methods: {
        jump(path) {
            this.$router.push({
                path: path,
            });
        },
        getmenu() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/menu",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    this.menuList = res.data.data;

                    // this.$router.options.routes[2].children.map((item, index) => {
                    //     if (data[index].path == item.path) {
                    //         this.menuList.push(item)

                    //     }
                    // })
                })
                .catch((res) => {
                    console.log(res);
                });
        }
    }
}
</script>
<style lang="less" scoped>
.nav_view {
    display: flex;

    .nav_title {
        span {
            line-height: 70px;
            height: 70px;
            color: #fff;
            font-weight: 600;
            display: block;
        }
    }
}
</style>