import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import axios from '../src/util/request'

const app = createApp(App)
app.provide('$axios', axios)
app.use(router)
.use(ViewUIPlus)
.mount('#app')





