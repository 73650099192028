<template>
    <div class="mechanism">
        <header-view></header-view>
        <div class="search_view" style="margin:40px 20px">
            <Row :gutter="16">
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">学校名称：</div>
                <Input v-model="schoolname" style="width: 440px" />
                </Col>
                <Col span="6" style="display:flex">
                <div style="min-width: 120px;">负责人电话：</div>
                <Input v-model="organizing_phone" style="width: 440px" />
                </Col>
                <Col span="6" style="display: flex;"><span style="min-width: 120px;">所在地区：</span>
                <Select v-model="district_id" filterable clearable style="width: 77.5%;">
                    <Option v-for="item in distList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                </Col>
                <Col span="6" style="display: flex;"><span style="min-width: 120px;">机构类型：</span>
                <Select v-model="school_type" filterable clearable style="width: 77.5%;">
                    <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.name }}</Option>
                </Select>
                </Col>
            </Row>
            <Row :gutter="16" style="margin-top: 20px;">
                <Col span="15">
                </Col>
                <Col span="3">
                <Button type="primary" style="width: 80%;" @click="getAdd">添加</Button>
                </Col>
                <Col span="3">
                <Button style="width: 80%;" type="primary" @click="getsearch">搜索</Button>
                </Col>
                <Col span="3">
                <Button style="width: 80%;" @click="research">重置</Button>
                </Col>
            </Row>
        </div>
        <div class="table_view">
            <Table :columns="columns" border :data="data">
            </Table>
            <Page @on-change="changePage" style="margin-top: 20px" page-size="15" :total="last_page" v-model="page"
                show-total />
        </div>
        <!-- 新增机构 -->
        <Modal title="添加机构" v-model="modaladd" class-name="vertical-center-modal">
            <Form ref="addform" :model="addform" :rules="ruleValidateAdd" :label-width="120">
                <FormItem label="机构名称" prop="schoolname">
                    <Input v-model="addform.schoolname"></Input>
                </FormItem>
                <FormItem label="负责人姓名" prop="organizing_name">
                    <Input v-model="addform.organizing_name"></Input>
                </FormItem>
                <FormItem label="负责人电话" prop="organizing_phone">
                    <Input v-model="addform.organizing_phone"></Input>
                </FormItem>
                <FormItem label="所在地区" prop="district_id">
                    <Select v-model="addform.district_id" filterable clearable style="width: 77.5%;">
                        <Option v-for="item in distList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="机构类型" prop="school_type">
                    <Select v-model="addform.school_type" filterable clearable style="width: 77.5%;">
                        <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="邮寄地址" prop="address">
                    <Input v-model="addform.address" type="textarea"></Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleSubmit('addform')">提交</Button>
                    <Button @click="handleReset('addform')" style="margin-left: 8px">取消</Button>
                </FormItem>
            </Form>
            <!-- <div v-slot:footer ></div> -->
            <template v-slot:footer>
                <div></div>
            </template>
        </Modal>
        <!-- 机构信息更改 -->
        <Modal title="机构编辑" v-model="modalChage" class-name="vertical-center-modal">
            <Form ref="changeForm" :model="changeForm" :rules="ruleValidateAdd" :label-width="120">
                <FormItem label="机构名称" prop="schoolname">
                    <Input v-model="changeForm.schoolname"></Input>
                </FormItem>
                <FormItem label="负责人姓名" prop="organizing_name">
                    <Input v-model="changeForm.organizing_name"></Input>
                </FormItem>
                <FormItem label="负责人电话" prop="organizing_phone">
                    <Input v-model="changeForm.organizing_phone"></Input>
                </FormItem>
                <FormItem label="所在地区" prop="district_id">
                    <Select v-model="changeForm.district_id" filterable clearable style="width: 77.5%;">
                        <Option v-for="item in distList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="机构类型" prop="school_type">
                    <Select v-model="changeForm.school_type" filterable clearable style="width: 77.5%;">
                        <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="邮寄地址" prop="address">
                    <Input v-model="changeForm.address" type="textarea"></Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleSubmitC('changeForm')">提交</Button>
                    <Button @click="handleResetC('changeForm')" style="margin-left: 8px">取消</Button>
                </FormItem>
            </Form>
            <template v-slot:footer>
                <div></div>
            </template>
        </Modal>
    </div>
</template>

<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
export default {
    components: {
        'header-view': header
    },
    data() {
        return {
            district_id: '',
            organizing_phone: '',
            school_type: '',
            schoolname: '',
            page: 1,
            data: [],
            last_page: 0,
            distList: [],
            columns: [{
                title: '序号',
                type: 'index',
                align: 'center',
                minWidth: 80,
            }, {
                title: '机构名称',
                key: 'schoolname',
                minWidth: 120,
            }, {
                title: '机构类型',
                key: 'school_type',
                minWidth: 120,
                render: (h, params) => {
                    if (params) {
                        if (params.row.school_type == '2') {
                            return h('span', '美术机构')
                        } else {
                            return h('span', '学校')
                        }
                    }
                }
            }, {
                title: '所在省市区',
                key: 'prov.name',
                minWidth: 200,
                render: (h, params) => {
                    if (params) {
                        if (params.row.dist != null && params.row.city != null && params.row.prov != null) {
                            return h('span', params.row.prov.name + '-' + params.row.city.name + '-' + params.row.dist.name)
                        } else if (params.row.city != null && params.row.prov != null) {
                            return h('span', params.row.prov.name + '-' + params.row.city.name)
                        } else if (params.row.prov != null) {
                            return h('span', params.row.prov.name)
                        } else {
                            return h('span', '暂无')
                        }
                    }
                }
            }, {
                title: '详细地址',
                key: 'address',
                minWidth: 120,
            }, {
                title: '操作',
                key: 'id',
                minWidth: 220,
                render: (h, params) => {
                    return h('div', [
                        h('span', {
                            class: 'checkBtn',
                            onClick: () => {
                                this.changethis(params.row)
                            }
                        }, '编辑'),
                        h('span', {
                            class: 'chceBtn_g',
                            onClick: () => {
                                this.deleteThis(params.row)
                            }
                        }, '删除')
                    ])
                }
            }],
            typeList: [
                {
                    name: '学校',
                    value: '1'
                }, {
                    name: '美术机构',
                    value: '2'
                }
            ],
            addform: {
                schoolname: '',
                district_id: '',
                organizing_name: '',
                organizing_phone: '',
                school_type: '',
                address: ''
            },
            ruleValidateAdd: {
                schoolname: [
                    { required: true, message: '机构名称不能为空', trigger: 'blur' }
                ],
                organizing_name: [
                    { required: true, message: '机构负责人姓名不能为空', trigger: 'blur' }
                ],
                organizing_phone: [
                    { required: true, message: '机构负责人电话不能为空', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '邮寄地址不能为空', trigger: 'blur' }
                ],
                school_type: [
                    { required: true, message: '请选择机构类型', trigger: 'change' }
                ],
            },
            changeForm: {
                schoolname: '',
                district_id: '',
                organizing_name: '',
                organizing_phone: '',
                school_type: '',
                address: ''
            },
            modaladd: false,
            footer: 'footer',
            modalChage: false,
            school_id: ''
        }
    },
    created() {
        if (localStorage.getItem("token")) {
            this.getSchoolList();
            this.getdistList()
        } else {
            this.$router.push({
                path: "/login",
            });
        }
    },
    methods: {
        getAdd() {
            this.modaladd = true;
        },
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    console.log(this.addform)
                    axios({
                        url: "https://metaverse.yccdl.cn/admin/organization/insertSchool",
                        method: 'post',
                        data: this.addform,
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: localStorage.getItem("token"),
                        },
                        withCredentials: false,
                    })
                        .then((res) => {
                            console.log(res);
                            this.$Modal.confirm({
                                title: res.data.msg,
                                content: '',
                                onOk: () => {
                                    this.$refs['addform'].resetFields();
                                    this.modaladd = false;
                                    this.data = [];
                                    this.page = 1;
                                    this.research();
                                }
                            })
                        })
                        .catch((res) => {
                            // console.log(res.data.data);
                        });
                } else {
                    this.$Message.error('Fail!');
                }
            })
        },
        handleReset(name) {
            this.$refs[name].resetFields();
            this.modaladd = false;
        },
        // 机构修改
        changethis(row) {
            console.log(row)
            this.changeForm = {
                schoolname: row.schoolname,
                district_id: row.dist != null ? row.dist.id : '',
                organizing_name: row.organizing_name,
                organizing_phone: row.organizing_phone,
                school_type: row.school_type,
                address: row.address
            }
            this.school_id = row.id;
            this.modalChage = true;
        },
        handleSubmitC(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    let form = this.changeForm;
                    form.school_id = this.school_id;
                    axios({
                        url: "https://metaverse.yccdl.cn/admin/organization/updateSchool",
                        method: 'post',
                        data: form,
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: localStorage.getItem("token"),
                        },
                        withCredentials: false,
                    })
                        .then((res) => {
                            console.log(res);
                            this.$Modal.confirm({
                                title: res.data.msg,
                                content: '',
                                onOk: () => {
                                    if (res.data.code == 200) {
                                        this.$refs['changeForm'].resetFields();
                                        this.school_id = '';
                                        this.modalChage = false;
                                        this.data = [];
                                        this.page = 1;
                                        this.research();
                                    }
                                }
                            })
                        })
                        .catch((res) => {
                            // console.log(res.data.data);
                        });
                    // this.$Message.success('Success!');
                } else {
                    this.$Message.error('Fail!');
                }
            })
        },
        handleResetC(name) {
            this.$refs[name].resetFields();
            this.modalChage = false;
        },
        deleteThis(row) {
            console.log(row)
            this.$Modal.confirm({
                title: '是否删除该机构',
                content: '',
                onOk: () => {
                    axios({
                        url: "https://metaverse.yccdl.cn/admin/organization/deleteSchool",
                        method: 'post',
                        data: {
                            school_id: row.id
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: localStorage.getItem("token"),
                        },
                        withCredentials: false,
                    })
                        .then((res) => {
                            console.log(res);
                            this.$Modal.confirm({
                                title: res.data.msg,
                                content: '',
                                onOk: () => {
                                    if (res.data.code == 200) {
                                        this.data = [];
                                        this.getSchoolList();
                                    }
                                }
                            })
                        })
                        .catch((res) => {
                            // console.log(res.data.data);
                        });
                }
            })
        },
        //       ----表单增删改查-------------
        getsearch() {
            this.data = [];
            this.page = 1;
            this.getSchoolList()
        },
        research() {
            this.district_id = '';
            this.organizing_phone = '';
            this.school_type = '';
            this.schoolname = '';
            this.getSchoolList();
        },
        changePage(e) {
            console.log(e);
            this.getSchoolList()
        },
        getSchoolList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/organization/getSchoolList",
                params: {
                    district_id: this.district_id,
                    organizing_phone: this.organizing_phone,
                    school_type: this.school_type,
                    schoolname: this.schoolname,
                    offset: 15,
                    page: this.page
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 401) {
                        this.$Modal.confirm({
                            title: '登录过期，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    this.data = res.data.data.data;
                    this.last_page = res.data.data.total;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        },
        getdistList() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/organization/getDistrict",
                params: {
                    city_id: localStorage.getItem('user_cityId')
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 401) {
                        this.$Modal.confirm({
                            title: '登录过期，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    this.distList = res.data.data;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        }
    }
}
</script>

<style lang="less" scoped>
.finance {
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: scroll;
    padding-top: 20px;

    .search_view {
        margin: 20px;
    }

    .table_view {
        margin: 20px 40px;
    }
}
</style>