<template>
    <div class="cityAdmin">
        <header-view></header-view>
        <div style="margin:20px">
            <Card style="width:100%;text-align: left;" >
                <ButtonGroup>
                    <Button v-for="(item,index) in btnList" :key="index" @click="dateType=index" :type="index==dateType?'primary':''">{{item}}</Button>
                </ButtonGroup>
            </Card>
            <Card style="width:100%;margin-top: 20px;" >
                <div class="card_view">
                    <div class="card_item">
                        <div class="title">{{ dateType == 0 ? '总' : '昨日' }}销售额</div>
                        <div class="num">{{ dateType == 0 ? data.all_pay_total : data.yesterday_pay_total }}</div>
                    </div>
                    <div class="card_item">
                        <div class="title">{{ dateType == 0 ? '总' : '昨日' }}分润</div>
                        <div class="num">{{ dateType == 0 ? data.all_share_total : data.yesterday_share_total }}</div>
                    </div>
                    <div class="card_item">
                        <div class="title">{{ dateType == 0 ? '总' : '昨日' }}会员购买量</div>
                        <div class="num">{{ dateType == 0 ? data.all_pay_vip_count : data.yesterday_pay_vip_count }}</div>
                    </div>
                </div>
            </Card>
            <Card style="width:100%;margin-top: 20px;">
                <div class="card_view">
                    <div class="card_item">
                        <div class="title">{{ dateType == 0 ? '总' : '昨日' }}业务码浏览量</div>
                        <div class="num">{{ dateType == 0 ? data.all_user_code_count : data.yesterday_user_code_count }}
                        </div>
                    </div>
                    <div class="card_item">
                        <div class="title">{{ dateType == 0 ? '总' : '昨日' }}用户资源库回访</div>
                        <div class="num">{{ dateType == 0 ? data.all_user_visit : data.yesterday_user_visit }}</div>
                    </div>
                    <div class="card_item">
                        <div class="title">昨日新增投稿量</div>
                        <div class="num">{{ data.yesterday_join_count }}</div>
                    </div>
                </div>
            </Card>
            <Card style="width:100%;margin-top: 20px;">
                <div class="card_view">
                    <div class="card_item">
                        <div class="title">昨日新增业务码</div>
                        <div class="num">{{ data.yesterday_sale }}
                        </div>
                    </div>
                    <div class="card_item">
                        <div class="title">昨日新增渠道</div>
                        <div class="num">{{ data.yesterday_school }}</div>
                    </div>
                    <div class="card_item">
                        <!-- <div class="title">昨日新增投稿量</div>
                        <div class="num">{{ data.yesterday_join_count }}</div> -->
                    </div>
                </div>
            </Card>
        </div>
    </div>
</template>

<script>
import header from '@/components/header/header.vue';
import axios from 'axios';
export default {
    components: {
        'header-view': header,
    },
    data() {
        return {
            data: {},
            dateType: 0,
            btnList: ['全部','昨日']
        }
    },
    created() {
        this.data = [];
        if (localStorage.getItem("token")) {
        this.getindex()
        } else {
            this.$router.push({
                path: "/login",
            });
        }
    },
    methods: {
        getindex() {
            axios({
                url: "https://metaverse.yccdl.cn/admin/sale/index",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: localStorage.getItem("token"),
                },
                withCredentials: false,
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 401) {
                        this.$Modal.confirm({
                            title: '登录过期，请重新登录',
                            content: '',
                            onOk: () => {
                                this.$router.push({
                                    path: "/login",
                                });
                            }
                        })
                        return;
                    }
                    this.data = res.data.data;
                })
                .catch((res) => {
                    // console.log(res.data.data);
                });
        }
    }
}
</script>

<style lang="less" scoped>
.cityAdmin {

    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: scroll;
    padding-top: 20px;

    .card_view {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .card_item {
            text-align: center;
            width: 33%;
            margin: 20px auto;

            .num {
                margin-top: 20px;
                font-size: 38px;

            }
        }
    }
}</style>